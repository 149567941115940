import { ICountryConfig } from '@query'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { TFunction } from 'i18next'
import { uniqBy } from 'lodash'

dayjs.extend(isSameOrBefore)

export * from './urls'

export enum NocfoPlan {
  FREE = 'FREE',
  PLUS = 'PLUS',
  PRO = 'PRO',
  ENTERPRISE = 'ENTERPRISE'
}

export const NOCFO_PLAN_OPTIONS = [
  {
    labelKey: `constants.nocfoPlan.${NocfoPlan.FREE}`,
    value: NocfoPlan.FREE
  },
  {
    labelKey: `constants.nocfoPlan.${NocfoPlan.PLUS}`,
    value: NocfoPlan.PLUS
  },
  {
    labelKey: `constants.nocfoPlan.${NocfoPlan.PRO}`,
    value: NocfoPlan.PRO
  },
  {
    labelKey: `constants.nocfoPlan.${NocfoPlan.ENTERPRISE}`,
    value: NocfoPlan.ENTERPRISE
  }
]

export enum NocfoPlanSource {
  STRIPE = 'STRIPE',
  AGENCY = 'AGENCY',
  MANUAL = 'MANUAL'
}

export enum UserRole {
  ADMIN = 'ADMIN',
  EDITOR = 'EDITOR',
  READ_ONLY = 'READ_ONLY'
}

export enum VatCode {
  TAX_FREE = 3,
  DOMESTIC_SALES_TAX = 1,
  DOMESTIC_PURCHASE_TAX = 2,
  DOMESTIC_ZERO_TAX_RATE = 4,
  EU_SALE_OF_GOODS = 5,
  EU_SALE_OF_SERVICES = 6,
  EU_PURCHASE_OF_GOODS = 7,
  EU_PURCHASE_OF_SERVICES = 8,
  PURCHASE_OF_GOODS_OUTSIDE_EU = 9,
  PURCHASE_OF_SERVICES_OUTSIDE_EU = 14,
  SALE_OF_CONSTRUCTION_SERVICES = 10,
  PURCHASE_OF_CONSTRUCTION_SERVICES = 11
}

export const VAT_CODES_FOR_REVERSE_CHARGE = [
  VatCode.EU_PURCHASE_OF_GOODS,
  VatCode.EU_PURCHASE_OF_SERVICES,
  VatCode.PURCHASE_OF_GOODS_OUTSIDE_EU,
  VatCode.PURCHASE_OF_CONSTRUCTION_SERVICES,
  VatCode.PURCHASE_OF_SERVICES_OUTSIDE_EU
]

export const VAT_CODES_IGNORE_VAT_IN_SALES = [
  VatCode.TAX_FREE,
  VatCode.EU_SALE_OF_GOODS,
  VatCode.EU_SALE_OF_SERVICES,
  VatCode.SALE_OF_CONSTRUCTION_SERVICES,
  VatCode.DOMESTIC_ZERO_TAX_RATE
]

export const VAT_CODES_FOR_SALES = [
  VatCode.TAX_FREE,
  VatCode.EU_SALE_OF_GOODS,
  VatCode.EU_SALE_OF_SERVICES,
  VatCode.SALE_OF_CONSTRUCTION_SERVICES,
  VatCode.DOMESTIC_SALES_TAX,
  VatCode.DOMESTIC_ZERO_TAX_RATE
]

export enum DocumentType {
  MANUAL_ENTRY = 0,
  VAT_ENTRY = 3,
  DEPRECATION = 4,
  INCOME_TAX = 6
}

export enum BlueprintType {
  PURCHASE = 'PURCHASE',
  SALES = 'SALES',
  MANUAL = 'MANUAL'
}

export const BLUEPRINT_TYPE_OPTIONS = [
  {
    labelKey: 'constants.documentType.purchase',
    value: BlueprintType.PURCHASE
  },
  {
    labelKey: 'constants.documentType.sales',
    value: BlueprintType.SALES
  },
  {
    labelKey: 'constants.documentType.other',
    value: BlueprintType.MANUAL
  }
]

export enum AccountType {
  ASS = 'ASS', // Vastaavaa
  ASS_DEP = 'ASS_DEP', // Poistokelpoinen omaisuus
  ASS_VAT = 'ASS_VAT', // Arvonlisäverosaatava
  ASS_REC = 'ASS_REC', // Siirtosaamiset
  ASS_PAY = 'ASS_PAY', // Pankkitili / käteisvarat
  ASS_DUE = 'ASS_DUE', // Myyntisaatavat

  LIA = 'LIA', // Vastattavaa
  LIA_EQU = 'LIA_EQU', // Oma pääoma
  LIA_PRE = 'LIA_PRE', // Edellisten tilikausien voitto
  LIA_DUE = 'LIA_DUE', // Ostovelat
  LIA_DEB = 'LIA_DEB', // Velat
  LIA_ACC = 'LIA_ACC', // Siirtovelat
  LIA_VAT = 'LIA_VAT', // Arvonlisäverovelka

  REV = 'REV', // Tulot
  REV_SAL = 'REV_SAL', // Liikevaihtotulot (myynti)
  REV_NO = 'REV_NO', // Verottomat tulot

  EXP = 'EXP', // Menot
  EXP_DEP = 'EXP_DEP', // Poistot
  EXP_NO = 'EXP_NO', // Vähennyskelvottomat menot
  EXP_50 = 'EXP_50', // Puoliksi vähennyskelpoiset menot
  EXP_TAX = 'EXP_TAX', // Verotili
  EXP_TAX_PRE = 'EXP_TAX_PRE' // Ennakkoverot
}

export const ACCOUNT_TYPES = [
  { value: AccountType.ASS, labelKey: 'constants.accountType.ASS' },
  { value: AccountType.ASS_DEP, labelKey: 'constants.accountType.ASS_DEP' },
  { value: AccountType.ASS_VAT, labelKey: 'constants.accountType.ASS_VAT' },
  { value: AccountType.ASS_REC, labelKey: 'constants.accountType.ASS_REC' },
  { value: AccountType.ASS_PAY, labelKey: 'constants.accountType.ASS_PAY' },
  { value: AccountType.ASS_DUE, labelKey: 'constants.accountType.ASS_DUE' },
  { value: AccountType.LIA, labelKey: 'constants.accountType.LIA' },
  { value: AccountType.LIA_EQU, labelKey: 'constants.accountType.LIA_EQU' },
  { value: AccountType.LIA_PRE, labelKey: 'constants.accountType.LIA_PRE' },
  { value: AccountType.LIA_DUE, labelKey: 'constants.accountType.LIA_DUE' },
  { value: AccountType.LIA_DEB, labelKey: 'constants.accountType.LIA_DEB' },
  { value: AccountType.LIA_ACC, labelKey: 'constants.accountType.LIA_ACC' },
  { value: AccountType.LIA_VAT, labelKey: 'constants.accountType.LIA_VAT' },
  { value: AccountType.REV, labelKey: 'constants.accountType.REV' },
  { value: AccountType.REV_SAL, labelKey: 'constants.accountType.REV_SAL' },
  { value: AccountType.REV_NO, labelKey: 'constants.accountType.REV_NO' },
  { value: AccountType.EXP, labelKey: 'constants.accountType.EXP' },
  { value: AccountType.EXP_DEP, labelKey: 'constants.accountType.EXP_DEP' },
  { value: AccountType.EXP_NO, labelKey: 'constants.accountType.EXP_NO' },
  { value: AccountType.EXP_50, labelKey: 'constants.accountType.EXP_50' },
  { value: AccountType.EXP_TAX, labelKey: 'constants.accountType.EXP_TAX' },
  { value: AccountType.EXP_TAX_PRE, labelKey: 'constants.accountType.EXP_TAX_PRE' }
]

export const NON_DISPLAYED_VAT_RATES = [VatCode.TAX_FREE]

export interface VatOption {
  label: string
  labelShort: string
  hint: string
  value: VatCode
  extraInfo?: string
}

export const VAT_CODE_OPTIONS: VatOption[] = [
  {
    label: 'constants.vatCode.none',
    labelShort: 'constants.vatCode.noneShort',
    hint: 'constants.vatCode.noneHint',
    value: VatCode.TAX_FREE
  },
  {
    label: 'constants.vatCode.domesticSales',
    labelShort: 'constants.vatCode.domesticSalesShort',
    hint: 'constants.vatCode.domesticSalesHint',
    value: VatCode.DOMESTIC_SALES_TAX
  },
  {
    label: 'constants.vatCode.domesticPurchase',
    labelShort: 'constants.vatCode.domesticPurchaseShort',
    hint: 'constants.vatCode.domesticPurchaseHint',
    value: VatCode.DOMESTIC_PURCHASE_TAX
  },
  {
    label: 'constants.vatCode.taxfreeSales',
    labelShort: 'constants.vatCode.taxfreeSalesShort',
    hint: 'constants.vatCode.taxfreeSalesHint',
    value: VatCode.DOMESTIC_ZERO_TAX_RATE,
    extraInfo:
      'https://www.vero.fi/yritykset-ja-yhteisot/verot-ja-maksut/arvonlisaverotus/ilmoitus-ja-maksuohjeet/arvonlisaveroilmoituksen-tayttoohje/'
  },
  {
    label: 'constants.vatCode.materialSalesEU',
    labelShort: 'constants.vatCode.materialSalesEUShort',
    hint: 'constants.vatCode.materialSalesEUHint',
    value: VatCode.EU_SALE_OF_GOODS,
    extraInfo:
      'https://www.vero.fi/yritykset-ja-yhteisot/verot-ja-maksut/arvonlisaverotus/ulkomaankauppa/yhteisokauppa/'
  },
  {
    label: 'constants.vatCode.serviceSalesEU',
    labelShort: 'constants.vatCode.serviceSalesEUShort',
    hint: 'constants.vatCode.serviceSalesEUHint',
    value: VatCode.EU_SALE_OF_SERVICES
  },
  {
    label: 'constants.vatCode.materialPurchaseEU',
    labelShort: 'constants.vatCode.materialPurchaseEUShort',
    hint: 'constants.vatCode.materialPurchaseEUHint',
    value: VatCode.EU_PURCHASE_OF_GOODS,
    extraInfo:
      'https://www.vero.fi/yritykset-ja-yhteisot/verot-ja-maksut/arvonlisaverotus/ulkomaankauppa/yhteisokauppa/'
  },
  {
    label: 'constants.vatCode.servicePurchaseEU',
    labelShort: 'constants.vatCode.servicePurchaseEUShort',
    hint: 'constants.vatCode.servicePurchaseEUHint',
    value: VatCode.EU_PURCHASE_OF_SERVICES,
    extraInfo:
      'https://www.vero.fi/yritykset-ja-yhteisot/verot-ja-maksut/arvonlisaverotus/ilmoitus-ja-maksuohjeet/arvonlisaveroilmoituksen-tayttoohje/'
  },
  {
    label: 'constants.vatCode.materialPurchaseOutsideEU',
    labelShort: 'constants.vatCode.materialPurchaseOutsideEUShort',
    hint: 'constants.vatCode.materialPurchaseOutsideEUHint',
    value: VatCode.PURCHASE_OF_GOODS_OUTSIDE_EU
  },
  {
    label: 'constants.vatCode.servicePurchaseOutsideEU',
    labelShort: 'constants.vatCode.servicePurchaseOutsideEUShort',
    hint: 'constants.vatCode.servicePurchaseOutsideEUHint',
    value: VatCode.PURCHASE_OF_SERVICES_OUTSIDE_EU
  },
  {
    label: 'constants.vatCode.constructionSales',
    labelShort: 'constants.vatCode.constructionSalesShort',
    hint: 'constants.vatCode.constructionSalesHint',
    value: VatCode.SALE_OF_CONSTRUCTION_SERVICES,
    extraInfo:
      'https://www.vero.fi/yritykset-ja-yhteisot/verot-ja-maksut/arvonlisaverotus/toimialakohtaista-tietoa/rakennusalan-kaannetty-arvonlisaverovelvollisuus/'
  },
  {
    label: 'constants.vatCode.constructionPurchase',
    labelShort: 'constants.vatCode.constructionPurchaseShort',
    hint: 'constants.vatCode.constructionPurchaseHint',
    value: VatCode.PURCHASE_OF_CONSTRUCTION_SERVICES,
    extraInfo:
      'https://www.vero.fi/yritykset-ja-yhteisot/verot-ja-maksut/arvonlisaverotus/toimialakohtaista-tietoa/rakennusalan-kaannetty-arvonlisaverovelvollisuus/'
  }
]

export const NOTIFYABLE_ERROR_CODES = [423, 409, 412, 428]

export enum ReportType {
  LEDGER = 'LEDGER',
  JOURNAL = 'JOURNAL',
  BALANCE_SHEET = 'BALANCE_SHEET',
  BALANCE_SHEET_RECONCILIATION = 'BALANCE_SHEET_RECONCILIATION',
  INCOME_STATEMENT = 'INCOME_STATEMENT',
  VAT_REPORT = 'VAT_REPORT'
}

export const REPORT_TYPE_OPTIONS = [
  {
    labelKey: 'constants.reportType.ledger',
    value: ReportType.LEDGER
  },
  {
    labelKey: 'constants.reportType.journal',
    value: ReportType.JOURNAL
  },
  {
    labelKey: 'constants.reportType.balanceSheet',
    value: ReportType.BALANCE_SHEET
  },
  {
    labelKey: 'constants.reportType.balanceSheetReconciliation',
    value: ReportType.BALANCE_SHEET_RECONCILIATION
  },
  {
    labelKey: 'constants.reportType.incomeStatement',
    value: ReportType.INCOME_STATEMENT
  },
  {
    labelKey: 'constants.reportType.vatReport',
    value: ReportType.VAT_REPORT
  }
]

export enum VatPeriod {
  NONE = -1,
  MONTH = 0,
  QUARTER = 1,
  YEAR = 2
}

export const VAT_PERIOD_OPTIONS = [
  {
    labelKey: 'constants.vatPeriod.none',
    value: VatPeriod.NONE
  },
  {
    labelKey: 'constants.vatPeriod.month',
    value: VatPeriod.MONTH
  },
  {
    labelKey: 'constants.vatPeriod.quarter',
    value: VatPeriod.QUARTER
  },
  {
    labelKey: 'constants.vatPeriod.year',
    value: VatPeriod.YEAR
  }
]

export enum ContactType {
  PERSON = 'PERSON',
  BUSINESS = 'BUSINESS'
}

export const CONTACT_TYPE_OPTIONS = [
  {
    labelKey: 'constants.contact.person',
    value: ContactType.PERSON
  },
  {
    labelKey: 'constants.contact.business',
    value: ContactType.BUSINESS
  }
]
export enum AnalyticsFrequency {
  D = 'D',
  W = 'W',
  M = 'M',
  Q = 'Q',
  Y = 'Y'
}

export enum AnalyticsRange {
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  MONTH_PREV = 'MONTH_PREV',
  PERIOD_CURRENT = 'PERIOD_CURRENT',
  PERIOD_PREVIOUS = 'PERIOD_PREVIOUS',
  PERIOD_LATEST = 'PERIOD_LATEST',
  CUSTOM = 'CUSTOM'
}

export const isCustomRange = (r: AnalyticsRange): boolean => r === AnalyticsRange.CUSTOM

export enum COASize {
  LG = 1,
  MD = 2,
  SM = 3
}

export const COA_SIZE_OPTIONS = [
  {
    labelKey: 'constants.coaSize.lg.name',
    descriptionKey: 'constants.coaSize.lg.description',
    value: COASize.LG
  },
  {
    labelKey: 'constants.coaSize.md.name',
    descriptionKey: 'constants.coaSize.md.description',
    value: COASize.MD
  },
  {
    labelKey: 'constants.coaSize.sm.name',
    descriptionKey: 'constants.coaSize.sm.description',
    value: COASize.SM
  }
]

export enum InvoiceStatus {
  DRAFT = 'DRAFT',
  ACCEPTED = 'ACCEPTED',
  PAID = 'PAID',
  CREDIT_LOSS = 'CREDIT_LOSS'
}

export enum NotificationType {
  CUSTOM = 'CUSTOM',
  FILE_UPLOADED_VIA_EMAIL = 'FILE_UPLOADED_VIA_EMAIL',
  VAT_PERIOD_REPORTING_DUE_1 = 'VAT_PERIOD_REPORTING_DUE_1',
  VAT_PERIOD_REPORTING_DUE_2 = 'VAT_PERIOD_REPORTING_DUE_2',
  BANK_INTEGRATION_SUSPENDED = 'BANK_INTEGRATION_SUSPENDED',
  BANK_INTEGRATION_GOING_TO_EXPIRE = 'BANK_INTEGRATION_GOING_TO_EXPIRE',
  INVOICE_RECEIVED = 'INVOICE_RECEIVED',
  USER_MENTIONED_IN_COMMENT = 'USER_MENTIONED_IN_COMMENT'
}

export enum NotificationIntent {
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  DANGER = 'DANGER'
}

export enum InvoicingLanguage {
  FI = 'fi',
  EN = 'en'
}

export const INVOICING_LANGUAGE_OPTIONS = [
  {
    value: InvoicingLanguage.FI,
    labelKey: 'language.finnish'
  },
  {
    value: InvoicingLanguage.EN,
    labelKey: 'language.english'
  }
]

export enum InvoiceDeliveryMethod {
  EINVOICE = 'EINVOICE',
  EMAIL = 'EMAIL'
}

export enum VatRateLabel {
  STANDARD = 'standard',
  REDUCED_A = 'reduced_a',
  REDUCED_B = 'reduced_b',
  ZERO = 'zero'
}

export interface VatRateOption {
  label: string
  value: string
}

interface GetVatRateLabelOptionsProps {
  countryConfig: ICountryConfig
  dateAt: dayjs.Dayjs
  t: TFunction
  uniqueLabels?: boolean
}

export const getVatRateLabelOptions = ({
  countryConfig,
  dateAt,
  t,
  uniqueLabels = false
}: GetVatRateLabelOptionsProps): VatRateOption[] => {
  const vatConfig = countryConfig.vat_configs
    .sort(({ effective_from: a }, { effective_from: b }) =>
      dayjs(b, 'YYYY-MM-DD').diff(dayjs(a, 'YYYY-MM-DD'), 'days')
    )
    .find(({ effective_from }) => dayjs(effective_from, 'YYYY-MM-DD').isSameOrBefore(dateAt))

  const options =
    vatConfig?.vat_rate_options.map(({ label, rate }) => ({
      label: t(`constants.vatRate.${label}`, { rate }),
      value: label
    })) || []

  return uniqueLabels ? uniqBy(options, 'value') : options
}

export const TIME_CONSTANTS = {
  SECONDS_IN_MINUTE: 60,
  MS_IN_SECOND: 1000
}

export enum InvoiceRepetitionOptions {
  NO_SELECTION = -1,
  MONTHLY = 1,
  YEARLY = 0
}

export const INVOICE_REPETITION_OPTIONS = [
  {
    labelKey: `constants.invoiceRepetition.options.${
      InvoiceRepetitionOptions[InvoiceRepetitionOptions.NO_SELECTION]
    }`,
    value: InvoiceRepetitionOptions.NO_SELECTION
  },
  {
    labelKey: `constants.invoiceRepetition.options.${
      InvoiceRepetitionOptions[InvoiceRepetitionOptions.MONTHLY]
    }`,
    value: InvoiceRepetitionOptions.MONTHLY
  },
  {
    labelKey: `constants.invoiceRepetition.options.${
      InvoiceRepetitionOptions[InvoiceRepetitionOptions.YEARLY]
    }`,
    value: InvoiceRepetitionOptions.YEARLY
  }
]

export enum InvoiceRepetitionDays {
  DAY_1 = 1,
  DAY_2 = 2,
  DAY_3 = 3,
  DAY_4 = 4,
  DAY_5 = 5,
  DAY_6 = 6,
  DAY_7 = 7,
  DAY_8 = 8,
  DAY_9 = 9,
  DAY_10 = 10,
  DAY_11 = 11,
  DAY_12 = 12,
  DAY_13 = 13,
  DAY_14 = 14,
  DAY_15 = 15,
  DAY_16 = 16,
  DAY_17 = 17,
  DAY_18 = 18,
  DAY_19 = 19,
  DAY_20 = 20,
  DAY_21 = 21,
  DAY_22 = 22,
  DAY_23 = 23,
  DAY_24 = 24,
  DAY_25 = 25,
  DAY_26 = 26,
  DAY_27 = 27,
  DAY_28 = 28,
  DAY_LAST = -1
}

export const INVOICE_REPETITION_DAYS = Object.keys(InvoiceRepetitionDays)
  .filter(key => !isNaN(InvoiceRepetitionDays[key]))
  .map(key => ({
    value: InvoiceRepetitionDays[key],
    labelKey:
      InvoiceRepetitionDays[key] === InvoiceRepetitionDays.DAY_LAST
        ? `constants.invoiceRepetition.days.${key}`
        : null
  }))

export enum InvoiceRepetitionMonths {
  JAN,
  FEB,
  MAR,
  APR,
  MAY,
  JUN,
  JUL,
  AUG,
  SEP,
  OCT,
  NOV,
  DEC
}

export const INVOICE_REPETITION_MONTHS = Object.keys(InvoiceRepetitionMonths)
  .filter(key => !isNaN(InvoiceRepetitionMonths[key]))
  .map(key => {
    return {
      value: InvoiceRepetitionMonths[key] + 1,
      labelKey: `constants.invoiceRepetition.months.${key}`
    }
  })

export enum ReferenceMethods {
  keep = 'KEEP',
  roll = 'ROLL'
}

export const INVOICE_REFERENCE_METHODS = [
  {
    value: ReferenceMethods.keep,
    labelKey: `constants.invoiceRepetition.referenceMethods.${ReferenceMethods.keep}`
  },
  {
    value: ReferenceMethods.roll,
    labelKey: `constants.invoiceRepetition.referenceMethods.${ReferenceMethods.roll}`
  }
]

export enum AttachmentAnalysisStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED'
}

export const ACC_TYPES_BANK = ['ASS_PAY']
export const ACC_TYPES_PURCHASE_PAYMENT = ['ASS_PAY', 'LIA_DUE']
export const ACC_TYPES_SALES_PAYMENT = ['ASS_PAY', 'ASS_DUE']
export const ACC_TYPES_PURCHASE = ['EXP', 'EXP_NO', 'EXP_50']
export const ACC_TYPES_SALES = ['REV', 'REV_SAL', 'REV_NO']

export enum KraviaActivityStatus {
  WAITING_FOR_APPROVAL = 'WaitingForApproval',
  SCHEDULED = 'Scheduled',
  STARTED = 'Started',
  COMPLETED = 'Completed',
  CANCELED = 'Canceled'
}

export enum KraviaInvoiceStatus {
  ALL = 'All',
  READY_FOR_COLLECTION = 'ReadyForCollection',
  OPEN = 'Open',
  CLOSED = 'Closed',
  PAUSED = 'Paused',
  ACTION_REQUIRED = 'ActionRequired',
  WITHDRAWN = 'Withdrawn',
  INVOICE = 'Invoice',
  LEGAL_ACTION = 'LegalAction',
  CREDIT_NOTE = 'CreditNote',
  INVALID = 'Invalid',
  REMINDER = 'Reminder',
  MONITORING = 'Monitoring',
  ANONYMIZED = 'Anonymized'
}

export const KraviaTerminalInvoiceStatuses = [
  KraviaInvoiceStatus.CLOSED,
  KraviaInvoiceStatus.WITHDRAWN
]

export enum EnablebankingCashAccountType {
  CACC = 'CACC',
  CARD = 'CARD',
  CASH = 'CASH',
  LOAN = 'LOAN',
  OTHR = 'OTHR',
  SVGS = 'SVGS'
}

interface EnablebankingCashAccountTypeOption {
  labelKey: string
  value: EnablebankingCashAccountType
}

export const ENABLEBANKING_CASH_ACCOUNT_TYPE_OPTIONS: EnablebankingCashAccountTypeOption[] = [
  {
    labelKey: 'constants.enablebankingCashAccountType.CACC',
    value: EnablebankingCashAccountType.CACC
  },
  {
    labelKey: 'constants.enablebankingCashAccountType.CARD',
    value: EnablebankingCashAccountType.CARD
  },
  {
    labelKey: 'constants.enablebankingCashAccountType.CASH',
    value: EnablebankingCashAccountType.CASH
  },
  {
    labelKey: 'constants.enablebankingCashAccountType.LOAN',
    value: EnablebankingCashAccountType.LOAN
  },
  {
    labelKey: 'constants.enablebankingCashAccountType.OTHR',
    value: EnablebankingCashAccountType.OTHR
  },
  {
    labelKey: 'constants.enablebankingCashAccountType.SVGS',
    value: EnablebankingCashAccountType.SVGS
  }
]
