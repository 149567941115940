import { Alert } from '@components'
import { IBankAccount } from '@query'
import { formatRelativeTime } from '@utils'
import { tryFormatIBAN } from '@utils/iban'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaCheck, FaSyncAlt } from 'react-icons/fa'
import styled from 'styled-components'

interface Props {
  account: IBankAccount
}

export const BankAccount: React.FC<Props> = ({ account }) => {
  const [t] = useTranslation()

  const isMissingInfo = !account.account_id

  return (
    <>
      <AccountComponent key={account.id}>
        <AccountNameRow>
          <AccountName>
            {t('settings.integrations.bank.defaultAccountName')}
            <IBAN>{tryFormatIBAN(account?.identifier)}</IBAN>
          </AccountName>
        </AccountNameRow>

        {!isMissingInfo && <AccountAlert account={account} />}

        <Alert
          type="warning"
          isVisible={isMissingInfo}
          description={t('settings.integrations.bank.accountEditButtonMissingInfo')}
        />
      </AccountComponent>
    </>
  )
}
interface AccountAlertProps {
  account: IBankAccount
}

const AccountAlert: React.FC<AccountAlertProps> = ({ account }) => {
  const [t] = useTranslation()

  let alertText = t('settings.integrations.bank.accountActive')
  let alertType = 'success'
  let AlertIcon = FaCheck

  if (account.last_sync_at) {
    alertText = t('settings.integrations.bank.accountSynced', {
      syncedAt: formatRelativeTime(account.last_sync_at)
    })
    alertType = 'success'
    AlertIcon = FaSyncAlt
  }

  if (!account.account_id) {
    return null
  }

  return alertText ? (
    <StyledAccountAlert className={alertType}>
      <AlertIcon /> {alertText}
    </StyledAccountAlert>
  ) : (
    <></>
  )
}

const AccountComponent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm}rem;
  padding: ${({ theme }) => theme.spacing.md}rem;
  background: white;
  border-radius: 1rem;
`

const IBAN = styled.div`
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  font-weight: 500;
  font-variant-numeric: tabular-nums;
  color: ${({ theme }) => theme.colors.metalGray};
`

const AccountNameRow = styled.div`
  display: flex;
`

const AccountName = styled.div`
  flex: 1;
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  color: ${({ theme }) => theme.colors.neutralBlack};
  font-weight: bold;
`

const StyledAccountAlert = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.3rem;

  &.success {
    color: ${({ theme }) => theme.colors.nocfoGreen};
  }

  &.warning {
    color: ${({ theme }) => theme.colors.nocfoYellow};
  }
`
