import { Button, Input } from '@components'
import { useTranslation } from 'react-i18next'
import { IInvoice, kraviaRegisterInvoicePayment } from '@query'
import { useForm } from 'react-hook-form'
import { useBusinessContext } from '@context'
import { useMutation, useQueryClient } from 'react-query'
import toast from 'react-hot-toast'
import dayjs from 'dayjs'
import {
  StyledButtonContainer,
  StyledFormContainer,
  StyledFullHeightContainer
} from '@pages/InvoicingPage/DebtCollection/trackerMessages/KraviaCommon.styled.tsx'

interface Props {
  invoice: IInvoice
  handleOnClose: () => void
}

interface MisdirectPaymentFormProps {
  payment_date: string
  amount: number
}

export const MisdirectPaymentForm: React.FC<Props> = ({ invoice, handleOnClose }) => {
  const queryClient = useQueryClient()
  const { businessId } = useBusinessContext()
  const { t } = useTranslation()
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<MisdirectPaymentFormProps>({
    defaultValues: {
      payment_date: dayjs().format('YYYY-MM-DD')
    }
  })

  const { mutateAsync, isLoading } = useMutation<unknown, unknown, MisdirectPaymentFormProps>(
    data => kraviaRegisterInvoicePayment({ businessId, invoiceId: invoice?.id }, data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([businessId, 'kravia_invoice_details', invoice?.id])
        handleOnClose()
      }
    }
  )

  const onSubmit = handleSubmit(async data => {
    await toast.promise(mutateAsync(data), {
      loading: t('general.loading'),
      success: t('general.saved'),
      error: t('general.error')
    })
  })

  return (
    <StyledFormContainer onSubmit={onSubmit}>
      <StyledFullHeightContainer>
        <Input
          type="date"
          {...register('payment_date', {
            required: { value: true, message: t('validation.required') }
          })}
          required={true}
          errors={errors?.payment_date}
          label={t('invoicing.debtCollection.misdirectModal.paymentDate')}
        />
        <Input
          {...register('amount', {
            valueAsNumber: true,
            required: { value: true, message: t('validation.required') }
          })}
          required={true}
          label={t('invoicing.debtCollection.misdirectModal.amount', {
            currency: invoice?.currency
          })}
          errors={errors?.amount}
          infos={t('invoicing.debtCollection.misdirectModal.amountInfo')}
          type="number"
          step="0.01"
        />
      </StyledFullHeightContainer>

      <StyledButtonContainer>
        <Button onClick={() => handleOnClose()}>{t('general.cancel')}</Button>
        <Button intent="success" disabled={isLoading} showSpinner={isLoading}>
          {t('general.save')}
        </Button>
      </StyledButtonContainer>
    </StyledFormContainer>
  )
}
