import { network } from '@root/utils'
import { handleReturn, QueryFunction, MutationFunction } from './root'

export enum AuditTrailItemType {
  COMMENT = 'comment',
  ACTIVITY = 'activity',
  HISTORY_BEGIN = 'history_begin'
}

interface IAuditTrailCommentItem {
  type: AuditTrailItemType.COMMENT
  item: IAuditTrailComment
  time: string
}

interface IAuditTrailActivityItem {
  type: AuditTrailItemType.ACTIVITY
  item: IAuditTrailObjectBase<IChangeDocument>
  time: string
}

interface IAuditTrailHistoryBeginItem {
  type: AuditTrailItemType.HISTORY_BEGIN
  time: string
}

export type IAuditTrailWrapper =
  | IAuditTrailCommentItem
  | IAuditTrailActivityItem
  | IAuditTrailHistoryBeginItem

export interface IAuditTrail extends Array<IAuditTrailWrapper> {}

export enum AuditTrailChangeReasons {
  IMPORT_TASK = 'IMPORT_TASK',
  MOCK_SUGGESTION = 'MOCK_SUGGESTION',
  ENABLE_BANKING_IMPORTED = 'ENABLE_BANKING_IMPORTED',
  FROM_SUGGESTION = 'FROM_SUGGESTION'
}

export const AuditTrailExportChangeReasons = {
  [AuditTrailChangeReasons.IMPORT_TASK]: AuditTrailChangeReasons.IMPORT_TASK,
  [AuditTrailChangeReasons.MOCK_SUGGESTION]: AuditTrailChangeReasons.MOCK_SUGGESTION,
  [AuditTrailChangeReasons.ENABLE_BANKING_IMPORTED]: AuditTrailChangeReasons.ENABLE_BANKING_IMPORTED
}

export enum AuditTrailFields {
  BLUEPRINT = 'blueprint',
  ATTACHMENTS = 'attachments',
  CONTACT = 'contact',
  DESCRIPTION = 'description',
  DATE = 'date',
  IS_FLAGGED = 'is_flagged',
  IS_LOCKED = 'is_locked'
}

export enum AuditTrailChangeType {
  CREATE = '+',
  DELETE = '-',
  UPDATE = '~'
}

export interface IAuditUser {
  id: number
  name: string
  email: string
}

export interface IAuditTrailObjectBase<T> {
  history_id: number
  changed_by: IAuditUser
  type: AuditTrailChangeType
  change_time: string
  change_reason: AuditTrailChangeReasons
  changes: T[]
}

export interface IChangeDocument {
  field: AuditTrailFields
  old_values: object[] | Record<string, any>[]
  new_values: object[] | Record<string, any>[]
  added?: object[] | Record<string, any>[]
  removed?: object[] | Record<string, any>[]
  changed?: object[] | Record<string, any>[]
}

interface IDocumentAuditTrailProps {
  documentId: number
  businessId: string
}

export const fetchDocumentAuditTrail: QueryFunction<
  IDocumentAuditTrailProps,
  IAuditTrail
> = async ({ businessId, documentId }) => {
  const url = `/v1/business/${businessId}/document/${documentId}/audit_trail/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET'
  })
  return data
}

export interface IAuditTrailComment {
  id: number
  created_at: string
  updated_at: string
  text: string
  document: number
  updated_by: IAuditUser
  created_by: IAuditUser
}
interface AuditTrailDocumentCommentCreateProps {
  documentId: number
  businessId: string
}

export const createAuditTrailDocumentComment: MutationFunction<
  AuditTrailDocumentCommentCreateProps,
  IAuditTrailComment
> = async ({ businessId, documentId }, comment) => {
  const url = `/v1/business/${businessId}/document/${documentId}/audit_trail/comment/`
  const { data, status } = await network.httpClient.request({
    url,
    method: 'POST',
    data: { text: comment.text, document: documentId },
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

interface AuditTrailDocumentCommentProps {
  businessId: string
  commentId: number
}

export const updateAuditTrailDocumentComment: MutationFunction<
  AuditTrailDocumentCommentProps,
  IAuditTrailComment
> = async ({ businessId, commentId }, comment) => {
  const url = `/v1/business/${businessId}/audit_trail/comment/${commentId}/`
  const { data, status } = await network.httpClient.request({
    url,
    method: 'PATCH',
    data: comment,
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

export const deleteAuditTrailDocumentComment: MutationFunction<
  AuditTrailDocumentCommentProps,
  void
> = async ({ businessId, commentId }) => {
  const url = `/v1/business/${businessId}/audit_trail/comment/${commentId}/`
  const { status, data } = await network.httpClient.request({
    url,
    method: 'DELETE',
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

interface IAuditTrailVisitedProps {
  businessId: string
  documentId: number
}

export const documentAuditTrailVisited: MutationFunction<IAuditTrailVisitedProps, void> = async ({
  businessId,
  documentId
}) => {
  const url = `/v1/business/${businessId}/document/${documentId}/audit_trail/visited/`
  const { status, data } = await network.httpClient.request({
    url,
    method: 'PATCH',
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}
