import { Alert, Button, Tab, Tabs } from '@components'
import { UserRole } from '@constants'
import { AttachmentSelector } from '@containers/AttachmentSelector'
import { DocumentBankDetails } from '@containers/DocumentBankDetails'
import { FeatureWrapper, useBusinessContext, usePermissionBoundary } from '@context'
import { IDocument, setFiles } from '@query'
import { formatDate, selectUserNotificationIcon } from '@utils'
import { motion } from 'framer-motion'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaInfoCircle, FaSyncAlt } from 'react-icons/fa'
import { FaMapLocationDot } from 'react-icons/fa6'
import { useMutation, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { AttachmentSuggestions } from './AttachmentSuggestions'
import { DocumentAuditTrail } from './DocumentAuditTrail'
import { EntryOverview } from './EntryOverview'

interface Props {
  document: IDocument
  setShowEdit: (value: boolean) => void
}

export const DocumentOverview: React.FC<Props> = ({ document, setShowEdit }) => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()

  const isEditor = usePermissionBoundary(UserRole.EDITOR)
  const queryClient = useQueryClient()

  const attachFilesMutation = useMutation<unknown, unknown, number[]>(
    ids => setFiles({ businessId, documentId: document.id }, ids),
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries([businessId, 'documents', document.id]),
          queryClient.invalidateQueries([businessId, 'documents', document.id, 'files'])
        ])
      }
    }
  )

  return (
    <Tabs>
      <Tab key="overview" icon={<FaInfoCircle />} name={t('document.tab.details')}>
        <DocumentOverviewWrapper>
          {document.number && (
            <DetailRow>
              <StyledHeader>{t('document.number')}</StyledHeader>
              <StyledValue data-test="document-overview-number">{document.number}</StyledValue>
            </DetailRow>
          )}

          <DetailRow>
            <StyledHeader>{t('document.date')}</StyledHeader>
            <StyledValue data-test="document-overview-date">
              {formatDate(document.date)}
            </StyledValue>
          </DetailRow>

          {document.contact_id && (
            <DetailRow>
              <StyledHeader>{t('document.contact')}</StyledHeader>
              <StyledValue>
                <Link
                  to={`/${businessId}/contacts?id=${document.contact_id}`}
                  data-test="document-overview-contact"
                >
                  {document.contact_name}
                </Link>
              </StyledValue>
            </DetailRow>
          )}

          {!document.contact_id && document.import_data?.contact_hint && (
            <DetailRow>
              <StyledHeader>{t('document.contact')}</StyledHeader>
              <StyledValue>{document.import_data?.contact_hint}</StyledValue>
            </DetailRow>
          )}

          <DetailRow>
            <StyledHeader>{t('document.description')}</StyledHeader>
            <StyledValue data-test="document-overview-description">
              {document.description || '-'}
            </StyledValue>
          </DetailRow>

          {!document.is_draft && (
            <DetailContainer>
              <StyledHeader>{t('document.entries')}</StyledHeader>
              <EntryOverview documentId={document.id} />
            </DetailContainer>
          )}

          {!document.is_draft && (
            <DetailContainer className="last">
              <StyledHeader>{t('document.attachments')}</StyledHeader>
              <AttachmentSelector
                value={document.attachment_ids}
                onChange={async values => {
                  await attachFilesMutation.mutateAsync(values)
                }}
                disabled={document.is_locked}
              />
            </DetailContainer>
          )}

          {/* Show suggestions if no attachments */}
          {document.attachment_ids?.length === 0 && (
            <FeatureWrapper feature="feature_analyze_attachments">
              <AttachmentSuggestions document={document} />
            </FeatureWrapper>
          )}

          {document.is_draft && isEditor && (
            <Alert
              title={t('document.draft')}
              description={
                <>
                  <p>{t('document.draftDescription')}</p>
                  <Button intent="primary" onClick={() => setShowEdit(true)}>
                    {t('document.recordDocument')}
                  </Button>
                </>
              }
            />
          )}
        </DocumentOverviewWrapper>
      </Tab>

      {document.import_data && (
        <Tab key="import_data" icon={<FaSyncAlt />} name={t('document.tab.bankTransactionDetails')}>
          <DocumentBankDetails import_data={document.import_data} />
        </Tab>
      )}

      {!document.is_draft && (
        <Tab
          key="audit_trail"
          icon={<FaMapLocationDot />}
          name={t('components.auditTrail.tabHeader')}
          notification_dot={selectUserNotificationIcon(document)}
        >
          <DocumentAuditTrail document={document}></DocumentAuditTrail>
        </Tab>
      )}
    </Tabs>
  )
}

const DetailRow = styled(motion.div).attrs({ layout: 'position' })`
  flex: 1;
  margin-bottom: ${({ theme }) => theme.spacing.lg}rem;
`

const DetailContainer = styled(motion.div).attrs({ layout: 'position' })`
  margin-bottom: ${({ theme }) => theme.spacing.lg}rem;

  &.last {
    border-bottom: none;
  }
`

const StyledHeader = styled.p`
  color: ${({ theme }) => theme.colors.metalGray};
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  margin-bottom: ${({ theme }) => theme.spacing.xs}rem;
  align-self: flex-start;
`

const StyledValue = styled.div`
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  color: ${({ theme }) => theme.colors.neutralBlack};
  margin: 0;
`

const DocumentOverviewWrapper = styled.div``
