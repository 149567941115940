import welcomeImg from '@assets/undraw/undraw_qa_engineers_dg-5-p.svg'
import { BasicPage, Button } from '@components'
import { createDemoBusiness, IBusiness } from '@query'
import React from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

export const DemoLandingPage: React.FC = () => {
  const [t] = useTranslation()
  const history = useHistory()
  const queryClient = useQueryClient()

  const onboardMutation = useMutation<Partial<IBusiness>>(() => createDemoBusiness(), {
    onSuccess: async data => {
      await queryClient.invalidateQueries('user')
      await queryClient.invalidateQueries(['businesses'])

      history.push(`/${data.business_id}/`)
    }
  })

  return (
    <BasicPage title={t('demo.welcome')} imageUrl={welcomeImg}>
      <PageContent>
        <h2>{t('demo.start')}</h2>
        <p>{t('demo.description1')}</p>
        <p>{t('demo.description2')}</p>
        <ButtonWrapper>
          <Button
            showSpinner={onboardMutation.isLoading}
            disabled={onboardMutation.isLoading}
            onClick={async () => {
              const promise = onboardMutation.mutateAsync()
              toast.promise(promise, {
                loading: t('demo.promise.loading'),
                success: t('demo.promise.success'),
                error: t('demo.promise.error')
              })
            }}
            intent="success"
            form="ny-form"
          >
            {t('demo.buttonText')}
          </Button>
        </ButtonWrapper>
      </PageContent>
    </BasicPage>
  )
}

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const ButtonWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;

  * {
    align-self: flex-end;
  }
`
