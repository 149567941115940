import { Alert, Button, Input, Prompt, Select } from '@components'
import { ReportType, UserRole, VatPeriod, VAT_PERIOD_OPTIONS } from '@constants'
import { PermissionBoundary, useBusinessContext, usePermissionBoundary } from '@context'
import { deleteVatPeriod, fetchVatPeriod, IVatPeriod, updateVatPeriod } from '@query'
import { formatDate } from '@utils'
import { Code, Heading, IconButton, Menu, MoreIcon, Pane, Popover, Position } from 'evergreen-ui'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { FaCheckCircle, FaFileDownload, FaTrash } from 'react-icons/fa'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { generateReport } from '../../utils/generateReport'
import { VatPeriodSteps } from './VatPeriodSteps'

interface Props {
  vatPeriodId: number
  onClose: () => void
}

export const VatPeriodDetails: React.FC<Props> = ({ vatPeriodId, onClose }) => {
  const [t] = useTranslation()
  const queryClient = useQueryClient()
  const { businessId } = useBusinessContext()
  const isEditor = usePermissionBoundary(UserRole.EDITOR)

  const [showDelete, setShowDelete] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitting }
  } = useForm()

  const getFormData = (data: Partial<IVatPeriod>) => ({
    start_date: data.start_date,
    period: data.period
  })

  const { data } = useQuery(
    [businessId, 'vat_periods', vatPeriodId],
    () => fetchVatPeriod({ businessId, vatPeriodId }),
    {
      onSuccess: data => reset(getFormData(data)),
      onError: () => onClose()
    }
  )
  const vatPeriod = data

  const updateMutation = useMutation<IVatPeriod, unknown, Partial<IVatPeriod>>(
    data => updateVatPeriod({ businessId, vatPeriodId }, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([businessId, 'vat_periods'])
        queryClient.invalidateQueries([businessId, 'vat_periods', vatPeriodId])
      }
    }
  )

  const deleteMutation = useMutation(() => deleteVatPeriod({ businessId, vatPeriodId }), {
    onSuccess: () => {
      queryClient.invalidateQueries([businessId, 'vat_periods'])
      setShowDelete(false)
      onClose()
    }
  })

  const save = handleSubmit(data => updateMutation.mutate(data))

  if (!vatPeriod) return null

  const VatDetailDropdown = () => (
    <Menu>
      <Menu.Group>
        <Menu.Item
          icon={<FaFileDownload />}
          onClick={() => {
            generateReport({
              business_id: businessId,
              type: ReportType.VAT_REPORT,
              columns: [
                {
                  date_from: vatPeriod.start_date,
                  date_to: vatPeriod.end_date
                }
              ]
            })
          }}
        >
          {t('vatPage.download')}
        </Menu.Item>
      </Menu.Group>
      {!vatPeriod.is_locked && (
        <>
          <Menu.Divider />
          <Menu.Group>
            <Menu.Item
              icon={<FaTrash color="red" />}
              intent="danger"
              onClick={() => setShowDelete(true)}
            >
              {t('vatPage.delete')}
            </Menu.Item>
          </Menu.Group>
        </>
      )}
    </Menu>
  )

  return (
    <Pane
      key={`details-${vatPeriod.id}`}
      width="100%"
      height="100%"
      padding="1rem"
      background="white"
      overflow="auto"
    >
      <Pane display="flex" marginBottom={24}>
        <Pane flex="1">
          <Heading size={600}>{t('vatPage.vatPeriod')}</Heading>
          <Code appearance="minimal">
            {formatDate(vatPeriod.start_date)} - {formatDate(vatPeriod.end_date)}
          </Code>
        </Pane>
        <Pane>
          {isEditor && (
            <Popover content={<VatDetailDropdown />} position={Position.BOTTOM_RIGHT}>
              <IconButton appearance="minimal" icon={MoreIcon} />
            </Popover>
          )}
        </Pane>
      </Pane>

      {vatPeriod.is_reported && !vatPeriod.is_locked ? (
        <Alert
          type="success"
          isVisible={vatPeriod.is_reported}
          title={t('vatPage.vatPaid')}
          description={t('vatPage.vatPaidInfo')}
        />
      ) : (
        vatPeriod.is_reported &&
        vatPeriod.is_locked && (
          <Alert
            type="success"
            isVisible={vatPeriod.is_reported}
            title={t('vatPage.vatPaid')}
            description={t('vatPage.vatPaidAndLockedInfo')}
          />
        )
      )}

      <Alert
        type="warning"
        isVisible={vatPeriod.has_ended && !vatPeriod.has_due_passed && !vatPeriod.is_reported}
        title={t('vatPage.notReported')}
        description={t('vatPage.notReportedDueDate', { dueDate: formatDate(vatPeriod.due_date) })}
      />

      <Alert
        type="error"
        isVisible={vatPeriod.has_due_passed && !vatPeriod.is_reported}
        title={t('vatPage.notReported')}
        description={t('vatPage.notReportedPastDue', { dueDate: formatDate(vatPeriod.due_date) })}
      />

      <Pane marginBottom={32} marginTop={16}>
        <form onSubmit={save}>
          <fieldset disabled={!isEditor}>
            <Input
              type="date"
              label={t('vatPage.startDate')}
              info={t('vatPage.startDateInfo')}
              {...register('start_date', { required: true })}
              disabled={vatPeriod.is_reported}
            />

            <Select
              {...register('period', { required: true, valueAsNumber: true })}
              label={t('vatPage.duration')}
              info={t('vatPage.durationInfo')}
            >
              {VAT_PERIOD_OPTIONS.map(
                ({ value, labelKey }) =>
                  value !== VatPeriod.NONE && (
                    <option key={value} value={value}>
                      {t(labelKey)}
                    </option>
                  )
              )}
            </Select>

            <Pane marginY={8}>
              <Alert type="info" isVisible={isDirty} description={t('vatPage.updatedAfterSave')} />
            </Pane>

            <Input
              type="date"
              label={t('vatPage.endDate')}
              info={t('vatPage.endDateInfo')}
              value={vatPeriod.end_date}
              disabled={true}
            />

            <Input
              type="date"
              label={t('vatPage.reportDate')}
              info={
                <Trans i18nKey="vatPage.reportDateInfo">
                  <a href="https://www.vero.fi/yritykset-ja-yhteisot/verot-ja-maksut/arvonlisaverotus/ilmoitus-ja-maksuohjeet/">
                    :)
                  </a>
                </Trans>
              }
              value={vatPeriod.due_date}
              disabled={true}
            />

            <PermissionBoundary requireRole={UserRole.EDITOR}>
              <Pane display="flex" justifyContent="flex-end">
                <Button
                  type="submit"
                  intent="success"
                  disabled={!isDirty}
                  showSpinner={isSubmitting}
                  icon={<FaCheckCircle />}
                >
                  {t('general.save')}
                </Button>
              </Pane>
            </PermissionBoundary>
          </fieldset>
        </form>
      </Pane>

      <PermissionBoundary requireRole={UserRole.EDITOR}>
        <Pane marginBottom={32}>
          <Heading marginBottom={16}>{t('vatPage.events')}</Heading>
          <VatPeriodSteps vatPeriod={vatPeriod} />
        </Pane>
      </PermissionBoundary>

      <Prompt
        isVisible={showDelete}
        title={t('general.areYouSure')}
        description={t('vatPage.areYouSure')}
        onClose={() => setShowDelete(false)}
        buttons={[
          {
            text: t('general.cancel'),
            action: () => setShowDelete(false)
          },
          {
            text: t('vatPage.delete'),
            intent: 'danger',
            action: () => deleteMutation.mutate()
          }
        ]}
      />
    </Pane>
  )
}
