import { AnimatePresence, motion, HTMLMotionProps } from 'framer-motion'
import React from 'react'
import { FaSpinner } from 'react-icons/fa'
import styled, { css } from 'styled-components'
import Color from 'color'

export interface ButtonProps extends Omit<HTMLMotionProps<'button'>, 'children'> {
  ref?: React.Ref<HTMLButtonElement>
  intent?: 'success' | 'primary' | 'default' | 'warning' | 'danger'
  isSecondary?: boolean
  icon?: React.ReactElement
  size?: 'sm' | 'md' | 'lg'
  iconRight?: React.ReactElement
  showSpinner?: boolean
  children?: React.ReactNode
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      intent,
      isSecondary,
      icon,
      size = 'md',
      iconRight,
      showSpinner,
      children,
      className = '',
      ...rest
    },
    ref
  ) => {
    const showLeftIcon = !!icon && !iconRight
    const showRightIcon = !!iconRight && !icon
    const showLeftSpinner = !iconRight && showSpinner
    const showRightSpinner = !!iconRight && !icon && showSpinner

    return (
      <StyledButton
        ref={ref}
        className={[intent, isSecondary ? 'is-secondary' : '', `size-${size}`, className].join(' ')}
        disabled={showSpinner}
        {...rest}
      >
        {(showLeftIcon || showLeftSpinner) && (
          <AnimatePresence initial={false} mode="wait">
            {showLeftSpinner ? (
              <IconWrapper key="spin">
                <FaSpinner className="spin" />
              </IconWrapper>
            ) : (
              icon && <IconWrapper key="icon">{icon}</IconWrapper>
            )}
          </AnimatePresence>
        )}
        {children && <span>{children}</span>}

        {(showRightIcon || showRightSpinner) && (
          <AnimatePresence initial={false} mode="wait">
            {showRightSpinner ? (
              <IconWrapper key="spin">
                <FaSpinner className="spin" />
              </IconWrapper>
            ) : (
              iconRight && <IconWrapper key="icon">{iconRight}</IconWrapper>
            )}
          </AnimatePresence>
        )}
      </StyledButton>
    )
  }
)

const buttonStyle = (color: string, intensity: number = 1) => css`
  transition: 0.2s;
  outline: none !important;

  &:not(.is-secondary) {
    color: ${({ theme }) =>
      Color(color).darken(0.3).isDark() ? theme.colors.neutralWhite : theme.colors.metalGray};
    background: ${color};

    &:hover,
    &:focus {
      filter: brightness(${100 - intensity * 10}%);
    }

    &:active {
      filter: brightness(${100 - intensity * 20}%);
    }
  }

  &.is-secondary {
    background: transparent;
    color: ${color};

    &:hover,
    &:focus {
      background: ${({ theme }) => theme.colors.neutralGray};
    }

    &:active {
      background: ${({ theme }) => theme.colors.neutralGray};
      filter: brightness(${100 - intensity * 10}%);
    }
  }
`

const StyledButton = styled(motion.button)<ButtonProps>`
  &.size-sm {
    padding: ${({ theme }) => theme.spacing.xs}rem ${({ theme }) => theme.spacing.sm}rem;
    font-size: ${({ theme }) => theme.fontSize.sm}rem;
  }

  &.size-md {
    padding: ${({ theme }) => theme.spacing.sm}rem ${({ theme }) => theme.spacing.md}rem;
    font-size: ${({ theme }) => theme.fontSize.md}rem;
  }

  &.size-lg {
    padding: ${({ theme }) => theme.spacing.md}rem ${({ theme }) => theme.spacing.lg}rem;
    font-size: ${({ theme }) => theme.fontSize.lg}rem;
  }

  border-radius: 1rem;
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  border: none;
  background: ${({ theme }) => theme.colors.mainBg};
  font-weight: 500;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.metalGray};
  text-decoration: none !important;

  & > * {
    align-self: center;
    text-decoration: none !important;
  }

  &.is-secondary {
    ${({ theme }) => buttonStyle(theme.colors.metalGray)};
  }

  &:not(.is-secondary) {
    ${({ theme }) => buttonStyle(theme.colors.neutralGray, 0.6)};
  }

  &.success {
    ${({ theme }) => buttonStyle(theme.colors.nocfoGreen)};
  }

  &.primary {
    ${({ theme }) => buttonStyle(theme.colors.nocfoBlue)};
  }

  &.warning {
    ${({ theme }) => buttonStyle(theme.colors.nocfoYellow)};
  }

  &.danger {
    ${({ theme }) => buttonStyle(theme.colors.nocfoRed)};
  }

  &[disabled] {
    opacity: 0.7;
    pointer-events: none;
  }
`

const IconWrapper = styled(motion.div).attrs({
  variants: {
    hide: { scale: 0.8, opacity: 0 },
    show: { scale: 1, opacity: 1 }
  },
  initial: 'hide',
  animate: 'show',
  exit: 'hide'
})`
  align-self: center;
  display: flex;
  & > * {
    align-self: center;
  }
`
