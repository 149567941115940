import React, { useState } from 'react'
import { IInvoice, kraviaOnboardStatus, kraviaRegisterInvoice } from '@query'
import { Button, ImageMessageBox, ModalV2 } from '@components'
import supportImgSrc from '@assets/undraw/undraw_showing_support_re_5f2v.svg'
import { OnboardingView } from '@pages/InvoicingPage/DebtCollection/trackerMessages/Onboarding/OnboardingView'
import { FaMoneyBillTrendUp } from 'react-icons/fa6'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useBusinessContext } from '@context'
import styled from 'styled-components'
import { formatCurrency } from '@utils'

interface Props {
  invoice: IInvoice
}

const MINIMUM_AMOUNT_TO_REGISTER = 1

export const StartDebtCollectionMessage: React.FC<Props> = ({ invoice }) => {
  const { t } = useTranslation()
  const [isOnboardingVisible, setIsOnboardingVisible] = useState(false)
  const { businessId } = useBusinessContext()

  const { isLoading: isLoadingData, data } = useQuery([businessId, 'kravia_onboard_status'], () =>
    kraviaOnboardStatus({ businessId })
  )

  const { mutateAsync, isLoading: isRegistering } = useMutation<unknown, unknown, number>(
    invoiceId => kraviaRegisterInvoice({ businessId, invoiceId })
  )

  const canBeRegistered =
    invoice?.total_amount + invoice?.total_vat_amount >= MINIMUM_AMOUNT_TO_REGISTER
  const isLoading = isLoadingData || isRegistering

  const registerInvoiceOrOnboard = async () =>
    data?.kravia_verified === false
      ? // CASE: Kravia is not yet enabled
        // > Open onboarding modal
        setIsOnboardingVisible(true)
      : // CASE: Kravia is enabled and onboarding is completed
        // > Start debt collection process
        await mutateAsync(invoice.id)

  return (
    <>
      <ImageMessageBox imgSrc={supportImgSrc} fillBackground={false}>
        <h4>{t('invoicing.debtCollection.register.title')}</h4>
        <p>{t('invoicing.debtCollection.register.description')}</p>
        <p>
          <Trans
            i18nKey="invoicing.debtCollection.register.description2"
            components={[
              <a
                key="debt-collection-link"
                href={t('link.docs.debtCollection')}
                target="_blank"
                rel="noreferrer"
              />
            ]}
          ></Trans>
        </p>

        <div style={{ display: 'flex', gap: '1rem' }}>
          <Button
            type="button"
            icon={<FaMoneyBillTrendUp />}
            onClick={registerInvoiceOrOnboard}
            disabled={isLoading || !canBeRegistered}
            showSpinner={isLoading}
            intent="primary"
          >
            {t('invoicing.actions.sendToDebtCollection')}
          </Button>
        </div>

        {!canBeRegistered && (
          <TotalAmountWarning>
            {t('invoicing.debtCollection.register.totalAmountWarning', {
              amount: formatCurrency(MINIMUM_AMOUNT_TO_REGISTER)
            })}
          </TotalAmountWarning>
        )}
      </ImageMessageBox>

      <ModalV2
        isVisible={isOnboardingVisible}
        handleOnClose={() => setIsOnboardingVisible(false)}
        width={600}
        height={800}
        header={t('invoicing.debtCollection.onboard.title')}
      >
        <OnboardingView data={data} onRegisterInvoice={() => mutateAsync(invoice.id)} />
      </ModalV2>
    </>
  )
}

const TotalAmountWarning = styled.div`
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  color: ${({ theme }) => theme.colors.orange};
  margin-top: ${({ theme }) => theme.spacing.sm}rem;
`
