import {
  Alert,
  Button,
  PopupMenu,
  PopupMenuButton,
  PopupMenuContainer,
  PopupMenuItem,
  Progress,
  Prompt
} from '@components'
import { useBusinessContext } from '@context'
import { useTheme } from '@hooks'
import { deleteBankIntegration, fetchBankIntegrations, IBankIntegration } from '@query'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { FaEllipsisH, FaSyncAlt, FaTrash } from 'react-icons/fa'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { BankAccount } from './BankAccount'
import { BankIntegrationLander } from './BankIntegrationLander'
import { RenewRequisitionPrompt } from './RenewRequisitionPrompt'

interface Props {
  integration: IBankIntegration
}

export const BankIntegration: React.FC<Props> = ({ integration }) => {
  const [t] = useTranslation()
  const queryClient = useQueryClient()
  const { businessId } = useBusinessContext()

  const [showDelete, setShowDelete] = useState<boolean>(false)
  const [showRenew, setShowRenew] = useState<boolean>(false)

  const { data } = useQuery([businessId, 'bank_integrations'], () =>
    fetchBankIntegrations({ businessId })
  )
  const integrations = data?.results

  const deleteIntegration = useMutation(
    () => deleteBankIntegration({ businessId, integrationId: integration.id }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([businessId, 'bank_integrations'])
        setShowDelete(false)
        toast.success(t('settings.integrations.bank.integrationDeleted'))
      },
      onError: () => {
        toast.error(t('general.error'))
      }
    }
  )

  const AccessGrantedText = React.memo<{ integration: IBankIntegration }>(({ integration }) => {
    const theme = useTheme()
    const now = Date.now()
    const grantedUntil = new Date(integration.access_granted_until).getTime()
    const grantedAt = new Date(integration.access_granted_at).getTime()
    const daysTotal = Math.round((grantedUntil - grantedAt) / 1000 / 60 / 60 / 24)
    const days = Math.round((grantedUntil - now) / 1000 / 60 / 60 / 24) // millis to days

    if (days < 0 || integration.is_staled) {
      return (
        <Alert
          type="warning"
          title={t('settings.integrations.bank.integrationOutdatedTitle')}
          description={
            <AlertContent>
              <p>{t('settings.integrations.bank.integrationOutdatedDescription')}</p>
              <Button onClick={() => setShowRenew(true)} icon={<FaSyncAlt />} intent="warning">
                {t('settings.integrations.bank.integrationOutdatedButton')}
              </Button>
            </AlertContent>
          }
        />
      )
    } else if (days <= 14) {
      return (
        <Alert
          type="info"
          title={t('settings.integrations.bank.integrationEndingTitle')}
          description={
            <AlertContent>
              <p>{t('settings.integrations.bank.integrationEndingDescription', { days })}</p>
              <Button onClick={() => setShowRenew(true)} icon={<FaSyncAlt />} intent="primary">
                {t('settings.integrations.bank.integrationEndingButton')}
              </Button>
              <Progress
                isReversed
                percent={daysTotal - days / daysTotal}
                progressColor={theme.colors.nocfoYellow}
              />
            </AlertContent>
          }
        />
      )
    } else {
      return (
        <Alert
          type="success"
          description={
            <AlertContent>
              {t('settings.integrations.bank.integrationActive', { days })}
              <Progress
                isReversed
                percent={daysTotal - days / daysTotal}
                progressColor={theme.colors.nocfoGreen}
              />
            </AlertContent>
          }
        />
      )
    }
  })

  if (integrations?.length === 0) {
    return <BankIntegrationLander />
  }

  return (
    <>
      <BankComponent>
        <IntegrationHeader>
          <IntegrationHeaderDetails>
            <StyledBankLogo src={integration.logo} />
          </IntegrationHeaderDetails>

          <PopupMenuContainer>
            <PopupMenu>
              <PopupMenuItem
                icon={<FaSyncAlt />}
                title={t('settings.integrations.bank.integrationEndingButton')}
                onClick={() => setShowRenew(true)}
              />
              <PopupMenuItem
                icon={<FaTrash color="red" />}
                title={t('general.delete')}
                onClick={() => setShowDelete(true)}
              />
            </PopupMenu>
            <PopupMenuButton>
              <IntegrationHeaderMenu>
                <Button icon={<FaEllipsisH />} />
              </IntegrationHeaderMenu>
            </PopupMenuButton>
          </PopupMenuContainer>
        </IntegrationHeader>

        <IntegrationDetails>
          <AccessGrantedText integration={integration} />
        </IntegrationDetails>

        <IntegrationAccounts>
          {integration.accounts
            .filter(({ enabled }) => enabled)
            .map(account => (
              <BankAccount key={account.id} account={account} />
            ))}
        </IntegrationAccounts>

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Link to={`/${businessId}/bank-integration-flow/edit?id=${integration.id}`}>
            <Button type="button">{t('general.edit')}</Button>
          </Link>
        </div>
      </BankComponent>

      <Prompt
        title={t('settings.integrations.bank.integrationDeleteTitle')}
        description={t('settings.integrations.bank.integrationDeleteDescription')}
        buttons={[
          {
            text: t('general.cancel'),
            intent: 'default',
            action: () => setShowDelete(false)
          },
          {
            text: t('general.delete'),
            intent: 'danger',
            icon: <FaTrash />,
            action: () => deleteIntegration.mutateAsync()
          }
        ]}
        isVisible={!!showDelete}
        onClose={() => setShowDelete(false)}
      />

      <RenewRequisitionPrompt
        isVisible={showRenew}
        onClose={() => setShowRenew(false)}
        integration={integration}
      />
    </>
  )
}

const BankComponent = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.neutralGray};
  padding: ${({ theme }) => theme.spacing.md}rem;
  gap: ${({ theme }) => theme.spacing.md}rem;
  border-radius: 1rem;
`

const IntegrationHeader = styled.div`
  display: flex;
`

const IntegrationHeaderMenu = styled.div``

const StyledBankLogo = styled.div<{ src: string }>`
  width: 200px;
  height: 50px;
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
`

const IntegrationHeaderDetails = styled.div`
  flex: 1;
  display: flex;
  gap: ${({ theme }) => theme.spacing.md}rem;
  align-items: center;

  h1,
  p {
    font-size: ${({ theme }) => theme.fontSize.md}rem;
    margin: 0;
  }

  p {
    color: ${({ theme }) => theme.colors.metalGray};
    font-weight: 500;
  }
`

const IntegrationDetails = styled.div`
  * {
    margin: 0;
  }
`

const IntegrationAccounts = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md}rem;
`

const AlertContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm}rem;
`
