import {
  Alert,
  AnimatedContentLoader,
  Button,
  Modal,
  ModalDialog,
  ModalOpenButton,
  Select,
  Switch,
  TextTooltip
} from '@components'
import { useBusinessContext } from '@context'
import { EnableBankingPSUType, fetchInstitutions, fetchRequisition } from '@query'
import cn from 'classnames'
import { motion } from 'framer-motion'
import countries from 'i18n-iso-countries'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { FaQuestionCircle } from 'react-icons/fa'
import { useQuery } from 'react-query'
import styled from 'styled-components'

enum EnableBankingCountry {
  Austria = 'AT',
  Belgium = 'BE',
  CzechRepublic = 'CZ',
  Germany = 'DE',
  Denmark = 'DK',
  Estonia = 'EE',
  Spain = 'ES',
  Finland = 'FI',
  France = 'FR',
  Greece = 'GR',
  Hungary = 'HU',
  Ireland = 'IE',
  Iceland = 'IS',
  Italy = 'IT',
  Lithuania = 'LT',
  Latvia = 'LV',
  Malta = 'MT',
  Netherlands = 'NL',
  Norway = 'NO',
  Poland = 'PL',
  Portugal = 'PT',
  Sweden = 'SE',
  Slovenia = 'SI',
  Slovakia = 'SK'
}

export const RequisitionModal: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const {
    t,
    i18n: { language: currentLanguage }
  } = useTranslation()
  const [selected, setSelected] = useState<[string, string]>()
  const [psuType, setPsuType] = useState<EnableBankingPSUType>(EnableBankingPSUType.BUSINESS)
  const [country, setCountry] = useState<EnableBankingCountry>(EnableBankingCountry.Finland)
  const [enableFetch, setEnableFetch] = useState(false)
  const { businessId } = useBusinessContext()

  const { data: institutions, isLoading } = useQuery(
    [businessId, 'institutions', country, psuType],
    () => fetchInstitutions({ businessId, country, psuType }),
    {
      enabled: enableFetch
    }
  )

  const { data: requisition, isFetching: isRequisitionLoading } = useQuery(
    [businessId, 'requisition', selected, psuType],
    () =>
      fetchRequisition({
        businessId,
        aspspCountry: selected?.[0],
        aspspName: selected?.[1],
        psuType: psuType
      }),
    {
      enabled: !!selected
    }
  )

  const isLinkDisabled = isRequisitionLoading || !requisition?.url

  return (
    <Modal onAppear={() => setEnableFetch(true)} onClose={() => setEnableFetch(false)}>
      {() => (
        <>
          <ModalOpenButton>{children}</ModalOpenButton>
          <ModalDialog height={900} width={500}>
            <StyledModalContent>
              <h3 style={{ marginBottom: '1.4rem' }}>
                {t('settings.integrations.bank.createModalTitle')}
              </h3>

              <Select
                label={t('settings.integrations.bank.selectCountry')}
                onChange={e => setCountry(e.target.value)}
              >
                {Object.keys(EnableBankingCountry).map(key => (
                  <option
                    selected={country === EnableBankingCountry[key]}
                    key={EnableBankingCountry[key]}
                    value={EnableBankingCountry[key]}
                  >
                    {countries.getName(EnableBankingCountry[key], currentLanguage)}
                  </option>
                ))}
              </Select>

              <SwitchWrapper>
                <Switch
                  id="psu-type-switch"
                  label={t('settings.integrations.bank.psuLabel')}
                  checked={psuType === EnableBankingPSUType.PERSONAL}
                  onChange={e => {
                    const isPersonal = e.target.checked
                    setPsuType(
                      isPersonal ? EnableBankingPSUType.PERSONAL : EnableBankingPSUType.BUSINESS
                    )
                  }}
                />

                <TextTooltip tooltip={t('settings.integrations.bank.psuTooltip')}>
                  <FaQuestionCircle />
                </TextTooltip>
              </SwitchWrapper>

              <MainContent>
                <AnimatedContentLoader isLoading={isLoading}>
                  <ScrollableContent>
                    {institutions?.map(institution => (
                      <InstitutionItem
                        key={institution.name}
                        onClick={e => {
                          setSelected([institution.country, institution.name])
                          e.preventDefault()
                        }}
                        className={cn({ isSelected: selected?.[1] === institution.name })}
                      >
                        <InstitutionLogo src={institution.logo} />
                        <InstitutionContent>
                          <InstitutionName>{institution.name}</InstitutionName>
                        </InstitutionContent>
                      </InstitutionItem>
                    ))}
                  </ScrollableContent>
                </AnimatedContentLoader>
              </MainContent>

              <Alert
                type="warning"
                isVisible={selected?.[1] === 'Holvi'}
                title={t('settings.integrations.bank.createModalHolviNoteTitle')}
                description={
                  <Trans i18nKey="settings.integrations.bank.createModalHolviNoteDescription">
                    <a
                      href={t('settings.integrations.bank.createModalHolviNoteLink')}
                      target="_blank"
                      rel="noreferrer"
                    />
                  </Trans>
                }
              />

              <a href={isLinkDisabled ? null : requisition?.url}>
                <Button
                  disabled={isLinkDisabled}
                  showSpinner={isRequisitionLoading}
                  style={{ justifyContent: 'center', marginTop: '1rem', width: '100%' }}
                  intent="primary"
                >
                  {t('settings.integrations.bank.createButton')}
                </Button>
              </a>
            </StyledModalContent>
          </ModalDialog>
        </>
      )}
    </Modal>
  )
}

const StyledModalContent = styled.div`
  background: ${({ theme }) => theme.colors.neutralWhite};
  padding: ${({ theme }) => theme.spacing.lg}rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const MainContent = styled.div`
  flex: 1;
  overflow: hidden;
  position: relative;
`

const ScrollableContent = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
`

const InstitutionItem = styled(motion.a).attrs({
  href: '#'
})`
  background: transparent;
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm}rem;
  border-radius: 1rem;
  padding: ${({ theme }) => theme.spacing.sm}rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.neutralBlack};
  text-decoration: none !important;

  &.isSelected {
    background: ${({ theme }) => theme.colors.neutralGray};
    color: ${({ theme }) => theme.colors.nocfoBlue};
  }

  &:hover {
    background: ${({ theme }) => theme.colors.neutralGray};
  }
`

const InstitutionLogo = styled.div<{ src: string }>`
  border: 0.1rem solid white;
  width: 2.5rem;
  height: 2.5rem;
  background: url(${({ src }) => src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 0.4rem;
`

const InstitutionContent = styled.div`
  display: flex;
  align-items: center;
`

const InstitutionName = styled.div`
  font-size: ${({ theme }) => theme.fontSize.md}rem;
`

const SwitchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.xs}rem;
  margin-bottom: ${({ theme }) => theme.spacing.md}rem;

  & > * {
    margin: 0;
  }

  svg {
    fill: ${({ theme }) => theme.colors.metalGray};
  }
`
