import { Alert, AnimatedContentLoader, Button, HeaderBlock } from '@components'
import { BlueprintType } from '@constants'
import { useBusinessContext } from '@context'
import { DocumentFormModal } from '@pages/DocumentPage/DocumentForm'
import { fetchPurchaseInvoice, updatePurchaseInvoice } from '@query'
import { formatDate } from '@utils'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaCheckCircle, FaFeatherAlt, FaMinusSquare } from 'react-icons/fa'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  getCombinedInvoiceStatusAlertType,
  getCombinedPurchaseInvoiceStatus
} from './combinedStatus'
import { InvoicePreviewModal } from './InvoicePreviewModal'
import { PurchaseInvoiceStatusBadge } from './InvoiceStatusBadge'
import {
  ContentFooter,
  DetailRow,
  ScrollableContent,
  StyledHeader,
  StyledProductDetails,
  StyledValue
} from './InvoicingDetails.styled'
import { PurchaseInvoicePaymentInfo } from './PurchaseInvoicePaymentInfo'
import { AttachmentSelector } from '@containers/AttachmentSelector'

interface Props {
  invoiceId: number
  onClose: () => void
}

export const PurchaseInvoiceDetails: React.FC<Props> = ({ invoiceId: purchaseInvoiceId }) => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()
  const queryClient = useQueryClient()
  const [showDocumentForm, setShowDocumentForm] = useState(false)

  const { data, isLoading } = useQuery([businessId, 'purchase_invoices', purchaseInvoiceId], () =>
    fetchPurchaseInvoice({ businessId, purchaseInvoiceId })
  )

  const markAsPaid = useMutation<unknown, unknown, boolean>(
    isPaid =>
      updatePurchaseInvoice(
        { businessId, purchaseInvoiceId },
        {
          is_paid: isPaid
        }
      ),
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries([businessId, 'purchase_invoices']),
          queryClient.invalidateQueries([businessId, 'combined_invoices'])
        ])
      }
    }
  )

  const menuItems = useMemo(() => {
    const items = [
      {
        Icon: FaFeatherAlt,
        name: t('invoicing.details.createDocument'),
        intent: 'default',
        onClick: () => setShowDocumentForm(true)
      }
    ]

    if (!data?.is_paid) {
      items.push({
        Icon: FaCheckCircle,
        name: t('invoicing.purchaseInvoiceDetails.markAsPaid'),
        intent: 'default',
        onClick: () => markAsPaid.mutateAsync(true)
      })
    } else {
      items.push({
        Icon: FaMinusSquare,
        name: t('invoicing.purchaseInvoiceDetails.markAsUnpaid'),
        intent: 'default',
        onClick: () => markAsPaid.mutateAsync(false)
      })
    }
    return items
  }, [data?.is_paid])

  const combinedStatus = data ? getCombinedPurchaseInvoiceStatus(data) : null
  const alertType = getCombinedInvoiceStatusAlertType(combinedStatus?.status)

  return (
    <>
      <StyledProductDetails>
        <HeaderBlock
          header={t('invoicing.purchaseInvoiceDetails.title')}
          subHeader={<></>}
          menuItems={menuItems}
        />

        <ScrollableContent>
          <AnimatedContentLoader isLoading={isLoading}>
            {data && (
              <>
                {alertType && (
                  <Alert
                    type={alertType}
                    description={t(`invoicing.combinedStatus.${combinedStatus?.status}`)}
                  />
                )}

                <DetailRow>
                  <StyledHeader>{t('invoicing.purchaseInvoiceDetails.sender')}</StyledHeader>
                  <StyledValue>{data.sender_name}</StyledValue>
                </DetailRow>

                <DetailRow>
                  <StyledHeader>{t('invoicing.purchaseInvoiceDetails.invoicingDate')}</StyledHeader>
                  <StyledValue>{formatDate(data.invoicing_date)}</StyledValue>
                </DetailRow>

                <DetailRow>
                  <StyledHeader>{t('invoicing.purchaseInvoiceDetails.dueDate')}</StyledHeader>
                  <StyledValue>{formatDate(data.due_date)}</StyledValue>
                </DetailRow>

                <DetailRow>
                  <StyledHeader>{t('invoicing.purchaseInvoiceDetails.status')}</StyledHeader>
                  <StyledValue>
                    <PurchaseInvoiceStatusBadge
                      isPaid={data.is_paid}
                      isPastDue={data.is_past_due}
                      hideIcon
                      size="md"
                    />
                  </StyledValue>
                </DetailRow>

                {data.original_attachment && (
                  <DetailRow>
                    <StyledHeader>{t('invoicing.purchaseInvoiceDetails.preview')}</StyledHeader>
                    <StyledValue>
                      <InvoicePreviewModal
                        title={t('invoicing.purchaseInvoiceDetails.preview')}
                        attachment={data.original_attachment}
                      >
                        <a href="#">{t('invoicing.details.previewDescription')}</a>
                      </InvoicePreviewModal>
                    </StyledValue>
                  </DetailRow>
                )}

                {data.attachments.length > 0 && (
                  <DetailRow>
                    <StyledHeader>{t('invoicing.purchaseInvoiceDetails.attachments')}</StyledHeader>
                    <StyledValue>
                      <div style={{ flex: 1 }}>
                        <AttachmentSelector
                          value={data.attachments}
                          disabled={true}
                          onChange={() => null}
                        />
                      </div>
                    </StyledValue>
                  </DetailRow>
                )}

                <PurchaseInvoicePaymentInfo invoice={data} />
              </>
            )}
          </AnimatedContentLoader>
        </ScrollableContent>
        <ContentFooter>
          {!data?.is_paid && (
            <Button
              icon={<FaCheckCircle />}
              intent="success"
              onClick={() => markAsPaid.mutateAsync(true)}
              disabled={markAsPaid.isLoading}
            >
              {t('invoicing.purchaseInvoiceDetails.markAsPaid')}
            </Button>
          )}
        </ContentFooter>
      </StyledProductDetails>

      <DocumentFormModal
        isVisible={showDocumentForm}
        onClose={() => setShowDocumentForm(false)}
        defaults={{
          date: data?.invoicing_date,
          description: data?.sender_name,
          blueprint_type: BlueprintType.SALES,
          attachment_ids: [data?.original_attachment?.id]
        }}
      />
    </>
  )
}
