import {
  autoUpdate,
  flip,
  offset,
  useFloating,
  useHover
} from '@floating-ui/react-dom-interactions'
import cn from 'classnames'
import copy from 'copy-to-clipboard'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaClipboard, FaClipboardCheck } from 'react-icons/fa'
import styled from 'styled-components'

interface Props {
  value: string
}

const CLOSE_DELAY = 1000
const OPEN_DELAY = 1400

export const CopyValue: React.FC<Props> = ({ value }) => {
  const [t] = useTranslation()
  const [infoOpen, setInfoOpen] = useState(false)
  const [valueCopied, setValueCopied] = useState(false)

  const { context, x, y, reference, floating, strategy } = useFloating({
    open: infoOpen,
    onOpenChange: setInfoOpen,
    whileElementsMounted: autoUpdate,
    placement: 'bottom-end',
    middleware: [offset(5), flip()]
  })

  useHover(context, {
    delay: {
      open: OPEN_DELAY,
      close: CLOSE_DELAY
    }
  })

  const copyToClipboard = (value: string) => {
    if (valueCopied) return
    copy(value)
    setInfoOpen(true)
    setValueCopied(true)
  }

  const onMouseLeave = () => {
    if (!valueCopied) return
    setTimeout(() => setValueCopied(false), CLOSE_DELAY)
  }

  return (
    <>
      <StyledCopyValueWrapper
        ref={reference}
        onClick={() => copyToClipboard(value)}
        onMouseLeave={() => onMouseLeave()}
      >
        <StyledCopyValue>{value}</StyledCopyValue>

        <StyledCopyButton type="button" className={cn({ success: valueCopied })}>
          {valueCopied ? <FaClipboardCheck /> : <FaClipboard />}
        </StyledCopyButton>
      </StyledCopyValueWrapper>

      <AnimatePresence>
        {infoOpen && (
          <StyledTooltip
            ref={floating}
            style={{
              top: y || 0,
              left: x || 0,
              position: strategy,
              width: 'max-content'
            }}
          >
            {valueCopied ? t('components.copyValue.copied') : t('components.copyValue.copy')}
          </StyledTooltip>
        )}
      </AnimatePresence>
    </>
  )
}

const StyledTooltip = styled(motion.div).attrs({
  initial: { opacity: 0, y: -10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -10 }
})`
  background: ${({ theme }) => theme.colors.menuBg};
  color: ${({ theme }) => theme.colors.neutralWhite};
  padding: ${({ theme }) => theme.spacing.xs}rem;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  border-radius: 0.6rem;
`

const StyledCopyValueWrapper = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.neutralGray};
  border-radius: 0.6rem;
  overflow: hidden;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.neutralBlack};
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    color: ${({ theme }) => theme.colors.nocfoBlue};
  }
`

const StyledCopyValue = styled.div`
  flex: 1;
  padding: ${({ theme }) => theme.spacing.sm}rem;
  font-family: 'Roboto Mono', monospace;
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const StyledCopyButton = styled.button`
  outline: none !important;
  border: none;
  padding: ${({ theme }) => theme.spacing.sm}rem;
  background: none;
  color: ${({ theme }) => theme.colors.metalGray};

  svg {
    transition: 0.4s;
  }

  &.success {
    svg {
      fill: ${({ theme }) => theme.colors.nocfoBlue} !important;
    }
  }
`
