import useResizeObserver from '@react-hook/resize-observer'
import { motion, useSpring } from 'framer-motion'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

const spring = {
  type: 'spring',
  stiffness: 300,
  damping: 40
}

interface PropsOut {
  flip: () => void
}

interface Props {
  maxPerspectiveRotation?: number
  enableFlipping?: boolean
  enableMovement?: boolean
  renderFront: React.FC<PropsOut>
  renderBack?: React.FC<PropsOut>
  rotationFactor?: number
  perspective?: number
}

export const FlipCard: React.FC<Props> = ({
  maxPerspectiveRotation = 10,
  enableFlipping = true,
  enableMovement = true,
  renderFront,
  renderBack,
  rotationFactor = 20,
  perspective = 1200
}) => {
  const [isFlipped, setIsFlipped] = useState(false)
  const ref = useRef(null)

  const cardFrontRef = useRef(null)
  const cardBackRef = useRef(null)

  const flip = useCallback(() => {
    setIsFlipped(value => !value)
  }, [])

  useResizeObserver(cardFrontRef, () => updateDimensions())
  if (renderBack) {
    useResizeObserver(cardBackRef, () => updateDimensions())
  }

  const getDimensionsByClassName = (name: string) => {
    const element = ref.current
    const rect = element?.getElementsByClassName(name)[0].getBoundingClientRect()
    return [rect?.width ?? 0, rect?.height ?? 0]
  }

  const [frontDimensions, setFrontDimensions] = useState(
    getDimensionsByClassName('flip-card-front')
  )
  const [backDimensions, setBackDimensions] = useState(
    renderBack && getDimensionsByClassName('flip-card-back')
  )
  const [width, height] = isFlipped ? backDimensions : frontDimensions

  const updateDimensions = useCallback(() => {
    if (renderBack) setBackDimensions(getDimensionsByClassName('flip-card-back'))
    setFrontDimensions(getDimensionsByClassName('flip-card-front'))
  }, [renderBack])

  const memoedFront = useMemo(() => renderFront({ flip }), [renderFront])
  const memoedBack = useMemo(() => renderBack && renderBack({ flip }), [renderBack])

  const [rotateXaxis, setRotateXaxis] = useState(0)
  const [rotateYaxis, setRotateYaxis] = useState(0)

  const handleMouseMove = useCallback(
    event => {
      const element = ref.current
      const elementRect = element.getBoundingClientRect()
      const elementCenterX = width / 2
      const elementCenterY = height / 2
      const mouseX = event.clientY - elementRect.y - elementCenterY
      const mouseY = event.clientX - elementRect.x - elementCenterX
      const degreeX = (mouseX / width) * rotationFactor
      const degreeY = (mouseY / height) * rotationFactor

      const limitedX =
        degreeX > 0
          ? Math.min(degreeX, maxPerspectiveRotation)
          : Math.max(degreeX, -maxPerspectiveRotation)
      const limitedY =
        degreeY > 0
          ? Math.min(degreeY, maxPerspectiveRotation)
          : Math.max(degreeY, -maxPerspectiveRotation)

      setRotateXaxis(limitedX)
      setRotateYaxis(limitedY)
    },
    [rotationFactor, maxPerspectiveRotation, width, height]
  )

  const handleMouseEnd = () => {
    setRotateXaxis(0)
    setRotateYaxis(0)
  }

  const dx = useSpring(0, spring)
  const dy = useSpring(0, spring)

  useEffect(() => {
    dx.set(-rotateXaxis)
    dy.set(rotateYaxis)
  }, [rotateXaxis, rotateYaxis])

  return (
    <motion.div
      onClick={enableFlipping ? flip : null}
      transition={spring}
      style={{
        perspective: `${perspective}px`,
        transformStyle: 'preserve-3d',
        width: '100%',
        height: height
      }}
    >
      <motion.div
        ref={ref}
        whileHover={{
          scale: enableMovement ? 1 : 1
        }} //Change the scale of zooming in when hovering
        onMouseMove={enableMovement ? handleMouseMove : null}
        onMouseLeave={handleMouseEnd}
        transition={spring}
        style={{
          width: '100%',
          height: '100%',
          rotateX: dx,
          rotateY: dy
        }}
      >
        <div
          style={{
            perspective: `${perspective}px`,
            transformStyle: 'preserve-3d',
            width: '100%',
            height: '100%'
          }}
        >
          <motion.div
            ref={cardFrontRef}
            className={'flip-card-front'}
            animate={{ rotateY: isFlipped ? -180 : 0 }}
            transition={spring}
            style={{
              width: '100%',
              height: 'fit-content',
              zIndex: isFlipped ? 0 : 1,
              backfaceVisibility: 'hidden',
              position: 'absolute'
            }}
          >
            {memoedFront}
          </motion.div>

          {renderBack && (
            <motion.div
              ref={cardBackRef}
              className={'flip-card-back'}
              initial={{ rotateY: 180 }}
              animate={{ rotateY: isFlipped ? 0 : 180 }}
              transition={spring}
              style={{
                width: '100%',
                height: 'fit-content',
                zIndex: isFlipped ? 1 : 0,
                backfaceVisibility: 'hidden',
                position: 'absolute'
              }}
            >
              {memoedBack}
            </motion.div>
          )}
        </div>
      </motion.div>
    </motion.div>
  )
}
