import React, { useCallback } from 'react'
import { Button } from '@components'
import { FaCheckCircle, FaMinusCircle, FaTimesCircle } from 'react-icons/fa'
import { useTheme } from '@hooks'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import {
  IInvoice,
  kraviaSetInvoiceAsCreditLoss,
  kraviaSetInvoiceAsPaid,
  kraviaWithdrawInvoice
} from '@query'
import { useMutation, useQueryClient } from 'react-query'
import { useBusinessContext } from '@context'
import {
  StyledButtonContainer,
  StyledFlexContainer,
  StyledFullHeightContainer,
  StyledRadio,
  StyledRadioDescription,
  StyledRadioGroup,
  StyledRadioHeader
} from '@pages/InvoicingPage/DebtCollection/trackerMessages/KraviaCommon.styled.tsx'

type StopAction = 'cancel' | 'markAsPaid' | 'creditLoss'

interface StopOption {
  key: StopAction
  Icon: React.ElementType
  color: string
  text: string
  description: string
}

interface Props {
  invoice: IInvoice
  handleOnClose: () => void
}

export const StopDebtCollectionForm: React.FC<Props> = ({ invoice, handleOnClose }) => {
  const theme = useTheme()
  const { businessId } = useBusinessContext()
  const [selected, setSelected] = React.useState<StopAction>(null)
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const mutationFunction = useCallback(() => {
    const params = { businessId, invoiceId: invoice?.id }
    switch (selected) {
      case 'cancel':
        return kraviaWithdrawInvoice(params)
      case 'markAsPaid':
        return kraviaSetInvoiceAsPaid(params)
      case 'creditLoss':
        return kraviaSetInvoiceAsCreditLoss(params)
    }
  }, [businessId, invoice?.id, selected])

  const { mutateAsync, isLoading } = useMutation(mutationFunction, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([businessId, 'kravia_invoice_details', invoice?.id])
      handleOnClose()
    }
  })

  const options: StopOption[] = [
    {
      key: 'cancel',
      Icon: FaTimesCircle,
      color: theme.colors.iconGray,
      text: t('invoicing.debtCollection.stopCollectionModal.cancelTitle'),
      description: t('invoicing.debtCollection.stopCollectionModal.cancelInfo')
    },
    {
      key: 'markAsPaid',
      Icon: FaCheckCircle,
      color: theme.colors.nocfoGreen,
      text: t('invoicing.debtCollection.stopCollectionModal.markAsPaidTitle'),
      description: t('invoicing.debtCollection.stopCollectionModal.markAsPaidInfo')
    },
    {
      key: 'creditLoss',
      Icon: FaMinusCircle,
      color: theme.colors.orange,
      text: t('invoicing.debtCollection.stopCollectionModal.creditLossTitle'),
      description: t('invoicing.debtCollection.stopCollectionModal.creditLossInfo')
    }
  ]

  return (
    <StyledFlexContainer>
      <StyledFullHeightContainer>
        <StyledRadioGroup>
          {options.map(option => (
            <StyledRadio
              key={option.key}
              className={cn({ selected: selected === option.key })}
              onClick={() => setSelected(option.key)}
              color={option.color}
            >
              <StyledRadioHeader>
                <option.Icon />
                <span>{option.text}</span>
              </StyledRadioHeader>
              <StyledRadioDescription>{option.description}</StyledRadioDescription>
            </StyledRadio>
          ))}
        </StyledRadioGroup>
      </StyledFullHeightContainer>

      <StyledButtonContainer>
        <Button onClick={() => handleOnClose()}>{t('general.cancel')}</Button>
        <Button
          intent={'primary'}
          onClick={() => mutateAsync()}
          showSpinner={isLoading}
          disabled={!selected || isLoading}
        >
          {t('general.save')}
        </Button>
      </StyledButtonContainer>
    </StyledFlexContainer>
  )
}
