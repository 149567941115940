import logoImgWhite from '@assets/nocfo-white.svg'
import { Badge, TextTooltip } from '@components'
import { getProductPageUrl, getSettingsPageUrl } from '@constants'
import { BusinessMenu } from '@containers/BusinessMenu'
import { Scrollbar } from 'react-scrollbars-custom'
import {
  FeatureWrapper,
  FreeContent,
  SubscriptionContent,
  useBusinessContext,
  useFeature
} from '@context'
import { useLocalStorage, useTheme } from '@hooks'
import { fetchIntegrationRenewInfo } from '@query'
import { useScreen } from '@utils'
import { AnimatePresence, motion, useInView } from 'framer-motion'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FaAddressCard,
  FaBook,
  FaCalculator,
  FaCalendarAlt,
  FaChartLine,
  FaChevronCircleRight,
  FaCloud,
  FaCog,
  FaExclamation,
  FaExclamationTriangle,
  FaFileDownload,
  FaInfoCircle,
  FaMask,
  FaShoppingBasket,
  FaStream,
  FaThLarge
} from 'react-icons/fa'
import { useQuery } from 'react-query'
import { matchPath } from 'react-router'
import { Link, NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { DateRangeSelect } from './components/DateRangeSelect'
import cn from 'classnames'
import { FaWallet } from 'react-icons/fa6'

interface MenuItemNotification {
  icon: React.ReactNode
  message: string
}

const MenuItem: React.FC<{
  icon: React.ReactElement
  name: string
  path: string
  target?: string
  isNarrow: boolean
  isNew?: boolean
  notification?: MenuItemNotification
}> = ({ icon, name, target = null, path, isNew, isNarrow, notification }) => {
  const isActive = matchPath(window.location.pathname, {
    path: path.split('?')[0],
    exact: true,
    strict: false
  })

  return (
    <StyledMenuItem to={path} target={target}>
      {isActive && <SelectionBackground layoutId="selected-background" />}
      <MenuItemContent>
        {icon}
        <AnimatePresence initial={false}>
          {!isNarrow && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="name"
            >
              {name}
            </motion.div>
          )}
        </AnimatePresence>

        {!isNarrow && isNew && (
          <div style={{ flex: 1 }}>
            <Badge type="success">{'New'}</Badge>
          </div>
        )}

        {!isNarrow && notification && (
          <TextTooltip tooltip={notification.message} placement="bottom">
            <AnimatedIconWrapper>{notification.icon}</AnimatedIconWrapper>
          </TextTooltip>
        )}
      </MenuItemContent>
    </StyledMenuItem>
  )
}

interface MainMenuProps {
  width?: number | string
  narrowWidth?: number | string
  isTogglable?: boolean
}

export const MainMenu: React.FC<MainMenuProps> = ({
  width = 300,
  narrowWidth = 80,
  isTogglable = false
}) => {
  const { width: sWidth, breakPoints } = useScreen()
  const {
    businessName,
    businessId,
    data: { is_trialing, trial_days_left, salaxy_enabled }
  } = useBusinessContext()

  const [t] = useTranslation()
  const [_isNarrow, setNarrow] = useLocalStorage<boolean>('menu-width', sWidth < breakPoints.lg)
  const isNarrow = isTogglable ? _isNarrow : false
  const theme = useTheme()
  const bankIntegrationsEnabled = useFeature('feature_bank_integrations')

  const menuOnTopIndicatorRef = useRef<HTMLDivElement>(null)
  const menuOnBottomIndicatorRef = useRef<HTMLDivElement>(null)
  const showTopIndicator = !useInView(menuOnTopIndicatorRef)
  const showBottomIndicator = !useInView(menuOnBottomIndicatorRef)

  const { data: integrationRenewData } = useQuery(
    [businessId, 'integration_renew_info'],
    () => fetchIntegrationRenewInfo({ businessId }),
    { enabled: !!bankIntegrationsEnabled }
  )
  const renewPending = integrationRenewData?.renew_pending
  const renewPendingSoon =
    !integrationRenewData?.renew_pending && integrationRenewData?.renew_pending_soon

  return (
    <StyledMainMenu initial={false} animate={{ width: isNarrow ? narrowWidth : width }}>
      <BusinessMenu>
        <BusinessMenuWrapper>
          <FaThLarge />
          <AnimatePresence initial={false}>
            {!isNarrow && (
              <motion.span
                style={{
                  flex: 1,
                  marginLeft: '1rem',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                {businessName}
              </motion.span>
            )}
          </AnimatePresence>
        </BusinessMenuWrapper>
      </BusinessMenu>

      <DateRangeSelectWrapper>
        <DateRangeSelect compact={isNarrow} />
      </DateRangeSelectWrapper>

      <MenuWrapper
        isNarrow={isNarrow}
        initial={false}
        style={{ padding: isNarrow ? '0 0 0 1rem' : '0 1rem' }}
        className={cn({
          showTopIndicator,
          showBottomIndicator
        })}
      >
        <Scrollbar noScrollX={true}>
          <StyledMenuGroup>
            <div ref={menuOnTopIndicatorRef} />
            <MenuItem
              icon={<FaChartLine />}
              isNarrow={isNarrow}
              name={t('mainMenu.frontpage')}
              path={`/${businessId}/`}
            />
            <MenuItem
              icon={<FaStream />}
              isNarrow={isNarrow}
              name={t('mainMenu.documents')}
              path={`/${businessId}/documents`}
            />
            <MenuItem
              icon={<FaBook />}
              isNarrow={isNarrow}
              name={t('mainMenu.accounts')}
              path={`/${businessId}/accounts`}
            />
            <MenuItem
              icon={<FaCloud />}
              isNarrow={isNarrow}
              name={t('mainMenu.files')}
              path={`/${businessId}/files`}
            />
          </StyledMenuGroup>

          <StyledMenuGroup>
            <MenuGroupHeader initial={false} animate={isNarrow ? 'hide' : 'show'}>
              {t('mainMenu.invoicingTitle')}
            </MenuGroupHeader>
            <MenuItem
              icon={<FaCalculator />}
              isNarrow={isNarrow}
              name={t('mainMenu.invoices')}
              path={`/${businessId}/invoicing`}
            />
            <MenuItem
              icon={<FaAddressCard />}
              isNarrow={isNarrow}
              name={t('mainMenu.contacts')}
              path={`/${businessId}/contacts`}
            />
            <MenuItem
              icon={<FaShoppingBasket />}
              isNarrow={isNarrow}
              name={t('mainMenu.products')}
              path={getProductPageUrl(businessId)}
            />
          </StyledMenuGroup>

          <StyledMenuGroup>
            <MenuGroupHeader initial={false} animate={isNarrow ? 'hide' : 'show'}>
              {t('mainMenu.reportingTitle')}
            </MenuGroupHeader>
            <FeatureWrapper feature="is_ny" reverse={true}>
              <MenuItem
                icon={<FaCalendarAlt />}
                isNarrow={isNarrow}
                name={t('mainMenu.periods')}
                path={`/${businessId}/accounting`}
              />
            </FeatureWrapper>
            <FeatureWrapper feature="vat_obliged">
              <MenuItem
                icon={<FaMask />}
                isNarrow={isNarrow}
                name={t('mainMenu.vatPeriods')}
                path={`/${businessId}/vat`}
              />
            </FeatureWrapper>
            <MenuItem
              icon={<FaFileDownload />}
              isNarrow={isNarrow}
              name={t('mainMenu.reports')}
              path={`/${businessId}/reporting`}
            />
          </StyledMenuGroup>

          <StyledMenuGroup>
            <MenuGroupHeader initial={false} animate={isNarrow ? 'hide' : 'show'}>
              {t('mainMenu.generalTitle')}
            </MenuGroupHeader>
            <FeatureWrapper feature="feature_salaries">
              <MenuItem
                icon={<FaWallet />}
                name={t('mainMenu.salaries')}
                path={`/${businessId}/salaries`}
                // Quick way to show badge until July for businesses that have not yet enabled salaries
                isNew={!salaxy_enabled && Date.now() < new Date('2024-07-01').getTime()}
                target={'_blank'}
                isNarrow={isNarrow}
              />
            </FeatureWrapper>
            <MenuItem
              icon={<FaCog />}
              isNarrow={isNarrow}
              name={t('mainMenu.settings')}
              path={getSettingsPageUrl(businessId, {
                page: renewPending || renewPendingSoon ? 'integrations' : 'settings'
              })}
              notification={
                renewPending
                  ? {
                      icon: <FaExclamation color={theme.colors.nocfoRed} />,
                      message: t('settings.integrations.bank.expiredIconTooltip')
                    }
                  : renewPendingSoon
                  ? {
                      icon: <FaExclamationTriangle color={theme.colors.nocfoYellow} />,
                      message: t('settings.integrations.bank.goingToExpireIconTooltip')
                    }
                  : undefined
              }
            />
            <div ref={menuOnBottomIndicatorRef} />
          </StyledMenuGroup>
        </Scrollbar>
      </MenuWrapper>

      <MenuFooter>
        <FooterRow>
          {!isNarrow && (
            <a
              href={t('link.webpage')}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none', flex: 1 }}
            >
              <AnimatedLogoWrapper key="full-logo">
                <FooterImg src={logoImgWhite} />
              </AnimatedLogoWrapper>
            </a>
          )}

          {isTogglable && (
            <MenuToggle
              className="menu-toggle"
              onClick={() => setNarrow(!isNarrow)}
              animate={{ rotate: isNarrow ? 0 : 180 }}
              narrow={isNarrow}
            >
              <FaChevronCircleRight />
            </MenuToggle>
          )}
        </FooterRow>

        {!isNarrow && is_trialing && (
          <SubscriptionContent>
            <FreeContent>
              <FeatureWrapper feature="is_billable">
                <TextTooltip
                  tooltip={t('settings.billing.freeTrialDescription')}
                  placement="top-start"
                >
                  <TrialingBadge to={getSettingsPageUrl(businessId, { page: 'plan' })}>
                    <FaInfoCircle />
                    <span>{t('mainMenu.trialBadge', { daysLeft: trial_days_left })}</span>
                  </TrialingBadge>
                </TextTooltip>
              </FeatureWrapper>
            </FreeContent>
          </SubscriptionContent>
        )}
      </MenuFooter>
    </StyledMainMenu>
  )
}

const StyledMainMenu = styled(motion.div)`
  height: 100%;
  background: ${({ theme }) => theme.colors.menuBg};
  display: flex;
  flex-direction: column;
  position: relative;
  white-space: nowrap;
  overflow: hidden;

  // hide scrollbar
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  .menu-toggle {
    opacity: 0;
    transition: opacity 0.2s;
  }

  &:hover {
    .menu-toggle {
      opacity: 1;
    }
  }
`

const MenuToggle = styled(motion.div)<{ narrow: boolean }>`
  cursor: pointer;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 1000px;
  z-index: 1;
  display: flex;
  justify-content: center;
  padding: 1rem;

  svg {
    align-self: center;
    transition: fill 0.4s;
    fill: ${({ theme }) => theme.colors.neutralGray};
    width: 1.3rem;
    min-width: 1.3rem;
    height: 1.3rem;
    min-height: 1.3rem;
    opacity: 0.6;
  }
`

const MenuFooter = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm}rem;
  opacity: 0.8;
  margin: ${({ theme }) => theme.spacing.md}rem;
`

const FooterRow = styled.div`
  display: flex;
  justify-content: center;
`

const AnimatedLogoWrapper = styled(motion.div).attrs({
  initial: { opacity: 0, x: -20 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -20 }
})``

const FooterImg = styled(motion.img)`
  align-self: flex-end;
  width: 120px;
  max-height: 30px;
  margin-left: -2px;
  margin-bottom: -2px;
`

const MenuWrapper = styled(motion.div)<{ isNarrow: boolean }>`
  flex: 1;
  position: relative;
  overflow: hidden;
  margin: 0;

  &.showTopIndicator {
    border-top: 1px solid ${({ theme }) => theme.colors.metalGray}66;
  }

  &.showBottomIndicator {
    border-bottom: 1px solid ${({ theme }) => theme.colors.metalGray}66;
  }

  .ScrollbarsCustom-Wrapper {
    ${({ isNarrow }) =>
      isNarrow ? 'inset: 0 15px 0 0 !important;' : 'inset: 0 20px 0 0 !important;'}
  }

  .ScrollbarsCustom-Track {
    top: 0 !important;
    ${({ isNarrow }) =>
      isNarrow
        ? `
      width: 6px !important;
      margin-right: 4px !important;
     `
        : 'width: 10px !important;'}
    background: #343c45 !important;
  }

  .ScrollbarsCustom-ThumbY {
    background: #1c222a !important;
  }
`

const BusinessMenuWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing.md}rem;
  height: 2.4rem;
  padding: ${({ theme }) => theme.spacing.sm}rem ${({ theme }) => theme.spacing.md}rem;
  color: ${({ theme }) => theme.colors.neutralGray};
  width: 100%;
  display: flex;
  align-items: flex-start;
  font-weight: 500;
  cursor: pointer;

  svg {
    color: ${({ theme }) => theme.colors.neutralGray};
    width: ${({ theme }) => theme.fontSize.md}rem;
    height: ${({ theme }) => theme.fontSize.md}rem;
    min-width: ${({ theme }) => theme.fontSize.md}rem;
    min-height: ${({ theme }) => theme.fontSize.md}rem;
  }
`

const StyledMenuGroup = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.md}rem;
  width: 100%;
`

const SelectionBackground = styled(motion.div).attrs({
  transition: { type: 'spring', duration: 0.6 }
})`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #00000040;
  border-radius: 1rem;
`

const MenuGroupHeader = styled(motion.h4).attrs({
  variants: {
    hide: { x: 0, opacity: 0 },
    show: { x: 0, opacity: 1 }
  }
})`
  height: 1rem;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  font-weight: 500;
  letter-spacing: 1px;
  margin-top: ${({ theme }) => theme.spacing.md}rem;
  padding: 0 ${({ theme }) => theme.spacing.md}rem;
  color: ${({ theme }) => theme.colors.metalGray};
`

const MenuItemContent = styled.div`
  position: absolute;
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm}rem;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.sm}rem ${({ theme }) => theme.spacing.md}rem;
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  color: ${({ theme }) => theme.colors.neutralGray};

  & > * {
    align-self: center;
  }

  svg {
    flex: 1;
    color: ${({ theme }) => theme.colors.neutralGray};
    width: ${({ theme }) => theme.fontSize.md}rem;
    height: ${({ theme }) => theme.fontSize.md}rem;
    min-width: ${({ theme }) => theme.fontSize.md}rem;
    min-height: ${({ theme }) => theme.fontSize.md}rem;
  }

  &:hover {
    opacity: 0.8;
  }

  .name {
    flex: 100%;
  }
`

const StyledMenuItem = styled(NavLink)`
  position: relative;
  display: flex;
  border-radius: 1rem;
  color: white;
  height: 3rem;
  width: 100%;
  align-items: center;
  justify-content: center;
`

const TrialingBadge = styled(Link)`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xxs}rem;
  padding: ${({ theme }) => theme.spacing.xs}rem;
  background: ${({ theme }) => theme.colors.orange}22;
  border: 2px solid ${({ theme }) => theme.colors.orange}66;
  color: ${({ theme }) => theme.colors.orange} !important;
  border-radius: 0.5rem;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  overflow: hidden;

  svg {
    min-width: 0.8rem;
    width: 0.8rem;
    min-height: 0.8rem;
    height: 0.8rem;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const AnimatedIconWrapper = styled(motion.div).attrs({
  initial: { scale: 1 },
  animate: {
    scale: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1.2, 1]
  },
  transition: {
    repeat: Infinity,
    duration: 4
  }
})``

const DateRangeSelectWrapper = styled.div`
  padding: 0 ${({ theme }) => theme.spacing.md}rem;
  margin-bottom: ${({ theme }) => theme.spacing.md}rem;
`
