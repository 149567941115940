import { AnimatePresence, motion } from 'framer-motion'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { FaChevronRight, FaInfoCircle } from 'react-icons/fa'
import styled from 'styled-components'
import { TextTooltip } from '@root/components'

interface Props {
  title: string
  info?: string
  openByDefault?: boolean
  children: React.ReactNode
  ref?: React.Ref<{ handleContainerOpen: (value: boolean) => void }>
}

export const ToggleContainer: React.FC<Props> = forwardRef(
  ({ title, info, openByDefault = false, children }, ref) => {
    const [isOpen, setIsOpen] = useState(openByDefault)

    ref &&
      useImperativeHandle(ref, () => ({
        handleContainerOpen: (value: boolean) => {
          setIsOpen(value)
        }
      }))

    return (
      <StyledToggleContainer>
        <StyledToggle isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
          <StyledTitle>
            <span>{title}</span>
            {info && (
              <TextTooltip tooltip={info}>
                <FaInfoCircle />
              </TextTooltip>
            )}
          </StyledTitle>
          <StyledToggleIconWrapper animate={isOpen ? 'open' : 'closed'}>
            <FaChevronRight />
          </StyledToggleIconWrapper>
        </StyledToggle>
        <AnimatePresence>
          {isOpen && (
            <StyledContentWrapper key="styled-content-wrapper">{children}</StyledContentWrapper>
          )}
        </AnimatePresence>
      </StyledToggleContainer>
    )
  }
)

const StyledToggleContainer = styled(motion.div)`
  border: 3px solid ${({ theme }) => theme.colors.mainBg};
  border-radius: 1rem;
`

const StyledTitle = styled.span`
  flex: 1;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs}rem;
`

const StyledToggle = styled.div<{ isOpen: boolean }>`
  display: flex;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.md}rem;
  & > * {
    align-self: center;
  }
`

const StyledContentWrapper = styled(motion.div).attrs({
  variants: {
    init: { opacity: 0, y: -5 },
    open: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 0 }
  },
  initial: 'init',
  animate: 'open',
  exit: 'exit'
})`
  padding: ${({ theme }) => theme.spacing.md}rem;
`

const StyledToggleIconWrapper = styled(motion.div).attrs({
  whileHover: { scale: 1.4 },
  variants: {
    open: { rotateZ: 90 },
    closed: { rotateZ: 0 }
  }
})``
