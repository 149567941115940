import { getSettingsPageUrl } from '@constants'
import { useBusinessContext } from '@context'
import { EnsureBankAccountSlide, PartnerSlide } from '@pages/BankIntegrationOnboardingPage/slides'
import { AspspsSlide } from '@pages/BankIntegrationOnboardingPage/slides/AspspsSlide'
import { showCompleteToast } from '@pages/BankIntegrationOnboardingPage/slides/CompleteSlide'
import { FinalizeSlide } from '@pages/BankIntegrationOnboardingPage/slides/FinalizeSlide'
import { motion } from 'framer-motion'
import React, { useMemo } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import styled from 'styled-components'

interface Props {
  baseUrl: string
}

export const BankIntegrationOnboardingPage: React.FC<Props> = ({ baseUrl }) => {
  const history = useHistory()
  const { businessId } = useBusinessContext()
  const businessUrl = useMemo(() => `/${businessId}`, [businessId])

  const urls = useMemo(
    () => ({
      START: `${baseUrl}/start`,
      PARTNERS: `${baseUrl}/partners`,
      ASPSPS: `${baseUrl}/aspsps`,
      EDIT: `${baseUrl}/edit`,
      FINALIZE: `${baseUrl}/finalize`,
      COMPLETE: `${baseUrl}/complete`
    }),
    [baseUrl]
  )

  return (
    <StyledPage>
      <StyledPageContent>
        <Switch>
          <Route path={urls.START}>
            <EnsureBankAccountSlide
              onHasBankAccount={() => history.push(urls.ASPSPS)}
              onHasNoBankAccount={() => history.push(urls.PARTNERS)}
              onSkip={() => history.push(businessUrl)}
            />
          </Route>

          <Route path={urls.PARTNERS}>
            <PartnerSlide
              onDone={() => history.push(businessUrl)}
              goBack={() => history.push(urls.START)}
            />
          </Route>

          <Route path={urls.ASPSPS}>
            <AspspsSlide goBack={() => history.goBack()} />
          </Route>

          <Route path={urls.FINALIZE}>
            <FinalizeSlide
              isEdit={false}
              onDone={() => {
                history.push(businessUrl)
                showCompleteToast()
              }}
              goBack={() => history.push(urls.ASPSPS)}
            />
          </Route>

          <Route path={urls.EDIT}>
            <FinalizeSlide
              isEdit={true}
              onDone={() => {
                history.push(getSettingsPageUrl(businessId, { page: 'integrations' }))
              }}
              goBack={() => history.goBack()}
            />
          </Route>
        </Switch>
      </StyledPageContent>
    </StyledPage>
  )
}

const StyledPage = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.neutralGray};
  padding: ${({ theme }) => theme.spacing.xxl}rem;

  @media (max-width: 768px) {
    padding: 0;
  }
`

const StyledPageContent = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 }
})`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 1000px;
  max-width: 600px;
  padding: ${({ theme }) => theme.spacing.md}rem;
  border-radius: 1rem;
  background: ${({ theme }) => theme.colors.neutralWhite};
  overflow: auto;

  @media (max-width: 768px) {
    max-width: 100%;
    max-height: 100%;
  }
`
