import dayjs from 'dayjs'

export type DayJSInput = Date | dayjs.Dayjs | string | undefined

const _toDayJs = (date: DayJSInput): dayjs.Dayjs => {
  if (!date) return dayjs()
  if (typeof date === 'string') return dayjs(date)
  if (date instanceof Date) return dayjs(date)
  return date
}

export const formatDate = (date: DayJSInput): string => _toDayJs(date).format('L')

export const formatRelativeTime = (date: DayJSInput): string => _toDayJs(date).fromNow()
